import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomerSliderURL } from "../../Services/apiConfig";

const initialState = {
  customerList: [],
  loading: false,
  error: null,
};
//read action
export const showUser = createAsyncThunk(
  "showUser",
  async (rejectWithValue) => {
    const response = await fetch(getCustomerSliderURL());
    try {
      const data = await response.json();
      // console.log("data", data.results);
      return data.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userDetail = createSlice({
  name: "userDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showUser.pending, (state) => {
        state.loading = true;
      })

      .addCase(showUser.fulfilled, (state, action) => {
        state.customerList = action.payload;
        state.loading = false;
      })

      .addCase(showUser.rejected, (state) => {
        state.loading = false;
        state.customerList = [];
      });
  },
});

export default userDetail.reducer;
