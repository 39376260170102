import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosObj from "../../Services/Api";
import { getCreateMultiTripURL } from "../../Services/apiConfig";

/**
 * created a initial state
 */
const initialState = {
  flightSearchMultiTrip: [],
  loading: false,
  error: null,
};

/**
 * createFlightSearchMultiTrip function
 */
export const createFlightSearchMultiTrip = createAsyncThunk(
  "/createMultiTripDetails",
  async (parameters) => {
    try {
      const response = await axiosObj.post(getCreateMultiTripURL(), parameters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
export const multiTripSlice = createSlice({
  name: "multiTripDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFlightSearchMultiTrip.pending, (state) => {
        state.loading = true;
      })

      .addCase(createFlightSearchMultiTrip.fulfilled, (state, action) => {
        state.flightSearchMultiTrip = action.payload;
        state.loading = false;
      })

      .addCase(createFlightSearchMultiTrip.rejected, (state) => {
        state.flightSearchMultiTrip = [];
        state.loading = false;
      });
  },
});

export const getCreateFlightMultiTrip = (state) => state.multiTripDetails.flightSearchMultiTrip;
export const getLoadingState = (state) => state.multiTripDetails.loading;

/**
 * exported the reducer
 */
export default multiTripSlice.reducer;
