import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Box,
  Button,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncAlt } from "@mui/icons-material";
import Travelers from "../../components/Travelers/Travelers";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";
import { fetchAirportList, getAirportList } from "../../Redux/Reducer/originDestinationFlightListReducer";

const MultiCity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  /**
   * Form fields
   */
  const [inputValue, setInputValue] = useState({
    originAirport: {
      code: "",
    },
    destinationAirport: {
      code: "",
    },
    originAirport1: {
      code: "",
    },
    destinationAirport1: {
      code: "",
    },
    range: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
    adultsCount: 1,
    childrenCount: 0,
    infantsCount: 0,
    selectedOption: "Economy",
    currency: "USD",
  });

  /**
   * fetch Airport data
   */
  const oriDesList = useSelector(getAirportList);
  const data = oriDesList?.data;

  useEffect(() => {
    dispatch(fetchAirportList(searchValue));
  }, [searchValue]);


  const jsonArrayObject = [];

  if (data && Array.isArray(data)) {
    for (const val of data) {
      const dynamicKey = val.code;
      jsonArrayObject.push({
        name: `${val.city} (${val.code}) ${val.country}`,
        code: dynamicKey,
      });
    }
  }

  const [validationErrors, setValidationErrors] = useState({
    originAirport: "",
    destinationAirport: "",
    originAirport1: "",
    destinationAirport1: "",
  });

  /**
   * datepicker
   * @param {*} date 
   * @returns 
   */
  const formatDate1 = (date) => {
    const defaultDate = new Date(); // Provide a default date
    const year = (date?.getFullYear() || defaultDate.getFullYear()).toString();
    const month = (date?.getMonth() + 1 || defaultDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = (date?.getDate() || defaultDate.getDate())
      .toString()
      .padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formattedStartDate = formatDate1(
    inputValue?.range?.startDate || new Date()
  );
  const formattedEndDate = formatDate1(
    inputValue?.range?.endDate || new Date()
  );

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  const handleInputClick = () => {
    setOpen(true);
  };

  const handleDateRangeChange = (item) => {
    setInputValue({
      ...inputValue,
      range: {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
      },
    });
  };

  const handleDocumentClick = (e) => {
    if (inputRef.current && inputRef.current.contains(e.target)) {
      return;
    }
    if (calendarRef.current && calendarRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const formatDate = (date) => {
    return format(date, "EEE, MMM d");
  };

  /**
   * onChange event
   * @param {*} field
   * @param {*} newValue
   */
  const handleInputChange = (field, newValue) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [field]: newValue,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
    /**
     * clear searched values
     */
    setSearchValue("");
  };

  /**
   * handle Switch event
   */
  const handleSwitchValues = () => {
    setInputValue((prevInputValue) => ({
      ...inputValue,
      originAirport: prevInputValue.destinationAirport,
      destinationAirport: prevInputValue.originAirport,
    }));
  };

  /**
   * handle Switch event
   */
  const handleSwitchValues1 = () => {
    setInputValue((prevInputValue) => ({
      ...inputValue,
      originAirport1: prevInputValue.destinationAirport1,
      destinationAirport1: prevInputValue.originAirport1,
    }));
  };

  /**
   * onSubmit event
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!inputValue.originAirport.code) {
      newErrors.originAirport = "Origin airport is required.";
    }
    if (!inputValue.destinationAirport.code) {
      newErrors.destinationAirport = "Destination airport is required.";
    }
    if (!inputValue.originAirport1.code) {
      newErrors.originAirport1 = "Origin airport is required.";
    }
    if (!inputValue.destinationAirport1.code) {
      newErrors.destinationAirport1 = "Destination airport is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setValidationErrors(newErrors);
    } else {
      const regex = /\(([^)]+)\)/; // Regular expression to match text inside parentheses
      const originMatch = inputValue.originAirport.code.match(regex);
      
      const originAirCode = originMatch[1];

      const destinationMatch = inputValue.destinationAirport.code.match(regex);
      
      const destinationAirCode = destinationMatch[1];

      const originMatch1 = inputValue.originAirport1.code.match(regex);
      
      const originAirCode1 = originMatch1[1];

      const destinationMatch1 = inputValue.destinationAirport1.code.match(regex);
      
      const destinationAirCode1 = destinationMatch1[1];

      const usersData = {
        dep1: originAirCode,
        arr1: destinationAirCode,
        dep2: originAirCode1,
        arr2: destinationAirCode1,
        number_of_adults: inputValue.adultsCount,
        number_of_childrens: inputValue.childrenCount,
        number_of_infants: inputValue.infantsCount,
        cabin_class: inputValue.selectedOption,
        currency: inputValue.currency,
        date1: formattedStartDate,
        arrival_date: formattedEndDate,
      };

      // navigate(
      //   `/cheap-flight-deal?departure_airport_code=${originAirCode}&arrival_airport_code=${destinationAirCode}&originAirCode1=${originAirCode1}&destinationAirCode1=${destinationAirCode1}&number_of_adults=${usersData.number_of_adults}&number_of_childrens=${usersData.number_of_childrens}&number_of_infants=${usersData.number_of_infants}&cabin_class=${usersData.cabin_class}&currency=${usersData.currency}&departure_date=${usersData.departure_date}&arrival_date=${usersData.arrival_date}&tripType=ROUND_TRIP`
      // );
    }
  };

  const handleOnKeyUp = (event) => {
    setSearchValue(event.target.value);
  };

  const airportListDropdown = searchValue ? jsonArrayObject.map((n) => n.name) : [];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item lg={10.8} container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Autocomplete
              fullWidth
              required
              freeSolo
              disableClearable
              options={airportListDropdown}
              value={inputValue.originAirport.code}
              onChange={(_, newValue) =>
                handleInputChange("originAirport", {
                  ...inputValue.originAirport,
                  code: newValue,
                })
              }
              onKeyUp={handleOnKeyUp}
              renderInput={(params) => (
                <Box style={{ position: "relative", display: "flex" }}>
                  <TextField
                    {...params}
                    label="Origin Airport"
                    variant="outlined"
                    error={validationErrors.originAirport ? true : false}
                  />
                  <IconButton
                    onClick={handleSwitchValues}
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: "0",
                      zIndex: 1,
                      transform: "translate(-25%, -25%)",
                    }}
                  >
                    <SyncAlt />
                  </IconButton>
                </Box>
              )}
            />
            <FormHelperText sx={{ color: "error.main", ml: 0 }}>
              {validationErrors.originAirport}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Autocomplete
              fullWidth
              required
              freeSolo
              disableClearable
              options={airportListDropdown}
              value={inputValue.destinationAirport.code}
              onChange={(_, newValue) =>
                handleInputChange("destinationAirport", {
                  ...inputValue.originAirport,
                  code: newValue,
                })
              }
              onKeyUp={handleOnKeyUp}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination Airport"
                  variant="outlined"
                  error={validationErrors.destinationAirport ? true : false}
                />
              )}
            />
            <FormHelperText sx={{ color: "error.main", ml: 0 }}>
              {validationErrors.destinationAirport}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <TextField
              ref={inputRef}
              fullWidth
              required
              className="inputBox"
              value={`${formatDate(inputValue.range.startDate)} to ${formatDate(
                inputValue.range.endDate
              )}`}
              readOnly
              onClick={handleInputClick}
            />
            <Box style={{ position: "absolute", zIndex: "2" }}>
              {open && (
                <Box
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box ref={calendarRef}>
                    <DateRange
                      ranges={[
                        {
                          startDate: inputValue.range.startDate,
                          endDate: inputValue.range.endDate,
                          key: "selection",
                        },
                      ]}
                      minDate={new Date()}
                      months={2}
                      direction="horizontal"
                      onChange={handleDateRangeChange}
                      showDateDisplay={false}
                      renderStaticRangeLabel={(date) => (
                        <span>
                          {`${date.toLocaleDateString()} (${date.toLocaleDateString(
                            undefined,
                            { weekday: "short" }
                          )}) ${date.getFullYear()} - Week ${date.toLocaleDateString(
                            undefined,
                            { week: "short" }
                          )}`}
                        </span>
                      )}
                      renderDay={(day) => (
                        <Box>
                          <span>{day.getDate()}</span>
                          <br />
                          <span>
                            {day.toLocaleDateString(undefined, {
                              weekday: "short",
                            })}
                          </span>
                          <br />
                          <span>{day.getFullYear()}</span>
                          <br />
                          <span>
                            Week{" "}
                            {day.toLocaleDateString(undefined, {
                              week: "short",
                            })}
                          </span>
                        </Box>
                      )}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", padding: "4px 6px" }}
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      ok
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            sx={{ position: "relative" }}
          >
            <Travelers
              adults={inputValue.adultsCount}
              children={inputValue.childrenCount}
              infants={inputValue.infantsCount}
              selectedOption={inputValue.selectedOption}
              onAdultsChange={(count) => handleInputChange("adultsCount", count)}
              onChildrenChange={(count) => handleInputChange("childrenCount", count)}
              onInfantsChange={(count) => handleInputChange("infantsCount", count)}
              onSelectedOptionChange={(option) =>
                handleInputChange("selectedOption", option)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} zIndex="1">
            <Autocomplete
              fullWidth
              required
              freeSolo
              disableClearable
              options={airportListDropdown}
              value={inputValue.originAirport1.code}
              onChange={(_, newValue) =>
                handleInputChange("originAirport1", {
                  ...inputValue.originAirport1,
                  code: newValue,
                })
              }
              onKeyUp={handleOnKeyUp}
              renderInput={(params) => (
                <Box style={{ position: "relative", display: "flex" }}>
                  <TextField
                    {...params}
                    label="Origin Airport"
                    variant="outlined"
                    error={validationErrors.originAirport1 ? true : false}
                  />
                  <IconButton
                    onClick={handleSwitchValues1}
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: "0",
                      zIndex: 1,
                      transform: "translate(-25%, -25%)",
                    }}
                  >
                    <SyncAlt />
                  </IconButton>
                </Box>
              )}
            />
            <FormHelperText sx={{ color: "error.main", ml: 0 }}>
              {validationErrors.originAirport1}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} zIndex="1">
            <Autocomplete
              fullWidth
              required
              freeSolo
              disableClearable
              options={airportListDropdown}
              value={inputValue.destinationAirport1.code}
              onChange={(_, newValue) =>
                handleInputChange("destinationAirport1", {
                  ...inputValue.originAirport,
                  code: newValue,
                })
              }
              onKeyUp={handleOnKeyUp}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination Airport"
                  variant="outlined"
                  error={validationErrors.destinationAirport1 ? true : false}
                />
              )}
            />
            <FormHelperText sx={{ color: "error.main", ml: 0 }}>
              {validationErrors.destinationAirport1}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={1.2}>
          <Button
            fullWidth
            required
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "orange",
              height: "52px",
              textTransform: "none",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default MultiCity;
