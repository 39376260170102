import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import RoundTrip from "./RoundTrip";
import MultiCity from "./MultiCity";
import OneWay from "./OneWay";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const FlightSearch = (props) => {
  const { oneWayFormValues, cheapFlightDeal, roundTripFormValues } = props;
  const location = useLocation();
  const [searchParams] = useSearchParams();

  /**
   * Tab
   */
  const [activeTab, setActiveTab] = useState(0);

  const tripType = searchParams.get("tripType");

  /**
   * set Active Tabs
   */
  useEffect(()=>{
    if (tripType === "ONE_WAY") {
      setActiveTab(0);
    } 
    if (tripType === "ROUND_TRIP") {
      setActiveTab(1);
    } 
    if (tripType === "MULTI_CITY") {
      setActiveTab(2);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          background:
            "linear-gradient(180deg, #eb643a, #eb643a 100%, #020024 123123%)",
          height: "auto",
          p: {
            lg: "2% 10% 3% 10%",
            md: "2% 25% 3% 25%",
            sm: "2% 20% 3% 20%",
            xs: "5%",
          },
        }}
      >
        {location.pathname === "/" ? (
          <Typography
            variant="h5"
            sx={{
              pb: "2%",
              fontSize: "28px",
              color: "#fff",
              fontWeight: "400",
            }}
          >
            Find Cheap Flight Tickets
          </Typography>
        ) : null}
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            p: "10px 20px 20px 20px",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: "10px",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="black"
            >
              <Tab label="One-way" sx={{ textTransform: "none" }} />
              <Tab label="Round-trip" sx={{ textTransform: "none" }} />
              <Tab label="Multi-City" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          {activeTab === 0 && (
            <Box>
              <OneWay
                oneWayFormValues={oneWayFormValues}
                cheapFlightDeal={cheapFlightDeal}
              />
            </Box>
          )}
          {activeTab === 1 && (
            <Box>
              <RoundTrip
                roundTripFormValues={roundTripFormValues}
                cheapFlightDeal={cheapFlightDeal}
              />
            </Box>
          )}
          {activeTab === 2 && (
            <Box>
              <MultiCity />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FlightSearch;
