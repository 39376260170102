import { React } from "react";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import FooterComp from "../../components/FooterComp/FooterComp";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";

const Contact = () => {
  return (
    <Box>
      <Grid container sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          sx={{
            px: "3%",
            mb: "5%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
            }}
          >
            Contact Information:
          </Typography>
          <br />
          <Typography variant="p">
            We're here to help you however we can. Don't be hesitant to get in
            touch.
            <br />
            <br />
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Help & Support:{" "}
            </Typography>
            Please feel free to contact us with queries or if you need help with
            making travel plans.
            <br />
            <br />
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Phone:{" "}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Availability:{" "}
            </Typography>
            24 hours a day, 7 days a week. <br />
            We promise to reply to messages right away. We will respond to all
            phone and email messages within 24 hours.
            <br />
            <br />
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Sales:
            </Typography>
            Fill out the form and select the appropriate subject if you are
            interested in learning more about marketing opportunities, company
            growth, or forming a strategic alliance.
            <br />
            <br />
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Affiliate & Partners:{" "}
            </Typography>
            Please use the previously mentioned contact information to inquire
            about joining as an Affiliate or Partner.
            <br />
            <br />
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Address:{" "}
            </Typography>
          </Typography>
        </Grid>
        <Grid lg={5} sx={{ px: "3%" }}>
          <MoreInformation />
        </Grid>
      </Grid>
      <FooterComp />
    </Box>
  );
};

export default Contact;
