import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCityTownDetailsURL} from "../../Services/apiConfig"

const initialState = {
  exampleList: [],
  loading: false,
  error: null,
};
 

export const fetchFlightDealsData = createAsyncThunk(
  "fetchFlightDealsData",
  async (rejectWithValue) => {
    const response = await fetch(getCityTownDetailsURL());

    try {
      const data = await response.json();
      // console.log("data", data.results);
      return data.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exampleListSlice= createSlice({
  name: "exampleLists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlightDealsData.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchFlightDealsData.fulfilled, (state, action) => {
        state.exampleList = action.payload;
        state.loading = false;
      })

      .addCase(fetchFlightDealsData.rejected, (state) => {
        state.loading = false;
        state.exampleList = [];
      });
  },
});

export default exampleListSlice.reducer;
