import { React } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import FooterComp from "../../components/FooterComp/FooterComp";
import MoreInformation from "../../components/MoreInformation/MoreInformation";

const CheaperFlightDeals = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "600",
              }}
            >
              Cheaper Flight Deals
            </Typography>
            <br />

            <Typography variant="p">
              Are you an experienced traveller looking for the most affordable
              airline deals? <br />
              <br />
              Look no further; we're here to reveal how to find those
              hard-to-find lower airline prices that can make your ideal
              vacation a reality. Buckle up as we delve into the subject of
              smart travel planning that could result in significant financial
              savings for you. <br />
              <br />
              1. Flexible Dates: Flexibility is your ticket to savings. Be open
              to adjusting your travel dates to find the best deals. <br />
              <br />
              2. Smart Booking: Booking your flight 6-8 weeks in advance usually
              means pocket-friendly fares. <br />
              <br />
              3.Compare Prices: Don't settle for the first offer. Use online
              platforms to compare prices from different airlines. <br />
              <br />
              4.Alternate Airports: Check out nearby airports; they might just
              have more budget-friendly options. <br />
              <br />
              5.Deal Alerts: Sign up for fare alerts. Catch the deal as soon as
              it drops. <br />
              <br />
              6.Opt for Layovers: Embrace layovers for potential savings. Plus,
              you get to explore new places, even if briefly! <br />
              <br />
              7. Loyalty Benefits: If you're a frequent flyer, loyalty programs
              and miles can make a big difference.
              <br />
              <br />
              8.Private Browsing: Use private browsing or incognito mode to
              dodge those sneaky price hikes.
              <br />
              <br />
              9.Bundle and Savings: Consider package deals that combine flights,
              hotels, and more for extra savings.
              <br />
              <br />
              10. Social Media: Follow airlines on social media. Flash sales and
              exclusive offers might just land in your feed.
              <br />
              <br />
              Remember, Connect-O-Fly is your wingman for affordable travel.
              Let's make your journey unforgettable without blowing your budget!
            </Typography>
          </Grid>

          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <FooterComp />
    </Box>
  );
};

export default CheaperFlightDeals;
