import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import data from "../../JsonFiles/UserApi/userapi.json";

const LowestFlightDeals = () => {
  const items = [
    "All",
    "Domestic",
    "India",
    "Europe",
    "South America",
    "Asia",
    "South East Asia",
    "Middle East",
    "Africa",
    "Australia",
  ];

  const [selectedCityTown, setSelectedCityTown] = useState("All");
  const [showAll, setShowAll] = useState(false);

  const handleCityTownClick = (citytown) => {
    setSelectedCityTown(citytown);
  };

  // Filter data based on selectedCityTown
  const filteredData =
    selectedCityTown === "All"
      ? data
      : data.filter((item) => item.citytown === selectedCityTown);

  // Display the first 6 items or all items based on the showAll state
  const displayedData = showAll ? filteredData : filteredData.slice(0, 6);

  const handleSeeMoreClick = () => {
    setShowAll(true);
  };

  return (
    <Box sx={{ backgroundColor: "#e1e3ef", pb: "2%", px: "15%" }}>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "700",
            mb: "4%",
            fontSize: "24px",
          }}
        >
          Our Lowest Flight Deals
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
        pb="3%"
        px="10%"
      >
        {items.map((item, index) => (
          <Grid item lg="auto" key={index}>
            <Link
              to="#"
              onMouseEnter={(e) =>
                (e.target.style.outline = "1px solid #1879c0")
              }
              onMouseLeave={(e) => (e.target.style.outline = "none")}
              style={{
                padding: "10px",
                textDecoration: "none",
                borderRadius: "5px",
                color: "#1879c0",
              }}
              onClick={() => handleCityTownClick(item)}
            >
              {item}
            </Link>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3}>
        {displayedData.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={index}
            className="parent"
            sx={{
              position: "relative",
            }}
          >
            <Box
              style={{
                position: "relative",
                borderRadius: "5px",
                overflow: "hidden",
                height: "250px",
              }}
            >
              <img
                src={item.image}
                alt="img"
                width="100%"
                height="250px"
                className="child"
                style={{
                  transition: "all 1.1s",
                  borderRadius: "5px",
                }}
              />{" "}
              <Box
                className="overlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
              ></Box>
            </Box>
            <Grid
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "10%",
                color: "white",
              }}
            >
              <Typography
                variant="span"
                style={{ fontSize: "18px", fontWeight: "700" }}
              >
                {item.originAirport}
              </Typography>
              <Typography
                variant="span"
                sx={{ fontSize: "18px", fontWeight: "700", mx: "5px" }}
              >
                to
              </Typography>
              <Typography
                variant="span"
                style={{ fontSize: "18px", fontWeight: "700" }}
              >
                {item.destinationAirport}
              </Typography>
              <br />
              <Typography
                variant="p"
                style={{ fontSize: "13px", color: "#fff" }}
              >
                {item.startDate}
              </Typography>
              <Typography variant="span" sx={{ mx: "5px" }}>
                to
              </Typography>
              <Typography
                variant="p"
                style={{ fontSize: "13px", color: "#fff" }}
              >
                {item.endDate}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="p"
                style={{
                  position: "absolute",
                  bottom: "7%",
                  right: "0px",
                  backgroundColor: "#ff8000",
                  borderRadius: "4px",
                  color: "white",
                  fontWeight: "700",
                  padding: "5px 10px",
                }}
              >
                {item.price}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {!showAll && filteredData.length > 6 && (
        <Button
          onClick={handleSeeMoreClick}
          variant="outlined"
          sx={{
            textTransform: "none",
            mx: { lg: "41%", md: "39%", sm: "34%", xs: "28%" },
            mt: "3%",
          }}
        >
          See More Deals
        </Button>
      )}
    </Box>
  );
};

export default LowestFlightDeals;
