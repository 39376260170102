import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import mask from "../../assets/images/mask.svg";

const TravelRestrictions = () => {

  return (
    <Box
      sx={{
        
        backgroundColor:"#e1e3ef",
        py:"5%",
         px:{
          lg:"15%",
          md:"10%",
          sm:"10%",
          xs:"5%"
       }
      }}    
    >
      <Box sx={{ backgroundColor: "white", borderRadius: "5px", p: "2%" }}>
        <Grid container alignItems="center" textAlign="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={1}>
            <img src={mask} alt="img" style={{ width: "50px" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} sx={{textAlign:"justify"}}>
            <Typography
              variant="p"
              sx={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "25px",
              }}
            >
              Want to Know About COVID-19 Travel Restrictions?
            </Typography>
            <br />

            <Typography variant="p">
              Before booking your flight, please check the latest COVID-19
              travel restrictions to India. Please continuously check all
              COVID-19 related restrictions directly with airlines prior to
              travel as they may change.
            </Typography>
          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={3} >
            <Button
              variant="contained"
              sx={{
                borderRadius: "5px",
                fontWeight: "bolder",
                textTransform: "none",
                zIndex:"1"
               
              
              }}
            >
              See Restrictions
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TravelRestrictions;
