import { React } from "react";
import FooterComp from "../../components/FooterComp/FooterComp";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import { Box, Grid, Typography } from "@mui/material";

const About = () => {
  return (
    <Box>
      <Grid container sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid
          lg={7}
          sx={{
            px: "3%",
            mb: "5%",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            About Us:
          </Typography>
          <br />
          <Typography variant="p">
            We at Connect-O-Fly are a team of passionate global travel
            professionals committed to providing first-rate service while
            offering excellent travel solutions at affordable prices.
            Connect-O-Fly is the best option, relied upon by millions of happy
            travelers, whether you're organizing an unforgettable family
            holiday, an exciting African safari, a lavish cruise, or just need
            help making hotel and flight reservations.
            <br />
            <br />
            Connect-O-Fly has established a niche in the travel industry by
            providing travelers with unrivalled value through our strategically
            positioned offices in the US, Canada, Australia, and India. We are
            your partners in making your travel aspirations a reality. We are
            particularly well-known for our incomparable rates on airline
            tickets for travelers departing from the US to any location on
            Earth.
            <br />
            <br />
            Our devoted team works constantly to reduce the anxiety associated
            with travel arrangements so you can focus on the anticipation of
            your impending trip. We have made it easier for you to acquire the
            greatest travel deals by making it easier to find the flights that
            perfectly suit your individual needs. We take pleasure in our hybrid
            model, which enables you to get significantly reduced airfare
            without sacrificing quality or convenience.
            <br />
            <br />
            Discover the world with Connect-O-Fly, where amazing savings and
            seamless travel experiences converge.
          </Typography>
          <br />
          <br />
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Our Mission: Crafting Unforgettable Journey.
          </Typography>
          <br />
          <Typography variant="p">
            Our joint goal at Connect-O-Fly is to create a company that is known
            for its outstanding customer service and incomparable prices. The
            motto of our company is "Create & Cherish Memories through Travel."
            <br />
            <br />
            We see travel as much more than just hotels, cruises, and
            aeroplanes. It's about the experiences and impressions that each
            voyage brings, enhancing lives and leaving a lasting legacy. Travel
            captures the essence of human experiences, whether it is reconciling
            with loved ones, discovering new horizons, establishing
            relationships with various cultures, or getting together with old
            and new friends.
            <br />
            <br />
            Our remarkable 5-star ratings and reviews bear witness to our
            commitment to quality and are a ringing monument to it. In fact, a
            whopping 92% of our devoted clients would gladly endorse us,
            demonstrating the confidence and satisfaction they place in our
            services.
            <br />
            <br />
            Connect-O-Fly - Your Partner in Crafting Extraordinary Memories
            through Travel.
          </Typography>
          <br />
          <br />
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Our Values: Guiding Principles for Exceptional Travel
          </Typography>
          <br />
          <Typography variant="p">
            Our basic beliefs at Connect-O-Fly determine who we are as a company
            and how we treat our devoted clients. Our guiding values underpin
            each encounter and experience we provide with a global perspective
            and unrelenting devotion.
            <br />
            <br />
            1. Cutting-edge Connectivity: To effortlessly serve our
            international clients, we use cutting-edge communication technology.
            This ensures that services are delivered quickly and effectively
            across borders.
            <br />
            <br />
            2. Dedicated Teamwork: We work hard as a team to reduce the stress
            associated with travel arrangements so that our customers can travel
            without fear. Our commitment guarantees that your travel
            requirements are met, freeing you to concentrate on making priceless
            memories.
            <br />
            <br />
            3. Tailored Travel Solutions: By emphasizing uniqueness, we've made
            it easier to match our clients' distinct preferences with the best
            travel options on the market. Your travel goals are at the forefront
            of what we have to offer.
            <br />
            <br />
            4. Innovative Hybrid Approach: We take great satisfaction in our
            inventive hybrid strategy, which enables our customers to get
            significantly reduced airfare without sacrificing quality. It is a
            paradigm that emphasizes excellence, accessibility, and convenience.
            <br />
            <br />
            5. Trust: Mutual trust, individual responsibility, and outstanding
            leadership serve as the cornerstones of our operations. These
            principles direct our interactions and promote a trusting and honest
            partnership.
            <br />
            <br />
            6. Empowered Culture: We value an environment where each team member
            is invested in your travel experience. We support employee ownership
            and leadership. This culture strengthens our dedication to ensuring
            your happiness.
            <br />
            <br />
            7. Quality in all areas: We have a strong commitment to quality,
            honesty, respect, and integrity. Your experience with us will be
            defined by unmatched quality and openness because to our commitment
            to these values.
            <br />
            <br />
            Discover the Connect-O-Fly difference – where values lead the way to
            exceptional travel experiences.
          </Typography>
          <br />
          <br />
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Our Process: Elevating Your Travel Experience
          </Typography>
          <br />
          <Typography variant="p">
            Learn more about the flawless journey Connect-O-Fly can provide for
            you. Our hybrid business model skillfully blends the ease of online
            booking with the attentive service of a committed travel counsellor
            who is on call 24/7. Experience the best of both worlds as we direct
            you towards economical travel options in a setting that is extremely
            user-friendly.
            <br />
            <br />
            1. Unlocking Value Through Partnerships: We are able to access
            significant savings thanks to our close relationships with airlines
            and consolidators, which allows us to reduce travel costs without
            sacrificing comfort. Our knowledgeable travel counsellors, who offer
            unrivalled one-on-one service customized to your needs, lead the way
            to remarkable discounts. Get ready to purchase premium airline seats
            at unbelievable discounts, frequently 30-45% below online rates.
            <br />
            <br />
            2. Unlocking Secret Deals: Have you ever wondered why we advise
            getting in touch with us directly? Our ability to gain access to
            special offline-only offers holds the key. Online publication of
            some of the most affordable airline contracts, ranging from business
            to economy class tickets, is prohibited. In especially during high
            seasons, we reveal discounts through our offline channels that can
            drastically alter your vacation budget. Bid adieu to normal internet
            prices and welcome our physical deals' astounding affordability.
            <br />
            <br />
            3. Whether your heart yearns for the romance of Italy, the untamed
            wilderness of Alaska, or any other location on your bucket list,
            we're here to make your dreams come true. Along with providing the
            best airfares, we are experts in designing custom itineraries that
            fit your interests, preferences, and spending limit.We pick
            experiences that redefine travel, from the remote regions of Asia to
            the cultural tapestries of Europe, the wild frontiers of Africa to
            the polar regions and Australasia.
            <br />
            <br />
            4. A Spectrum of Knowledge: We are dedicated to more than just
            flying. Explore the realm of professional advice, which includes
            information on cruises, lodging, and engaging local excursions.
            Explore the heart of India, travel through the mysticism of
            Southeast Asia, go on a safari in Africa, or be lost in the allure
            of Italy, Greece, the jewels of Scandinavia, and the mesmerizing
            scenery of Alaska. We specialize in all-inclusive getaways to
            Cancun, Hawaii, the Caribbean, and other destinations for North
            Americans.No matter if you're a family, couple, group of friends,
            single traveler, or experienced explorer yourself, our destination
            experts design each tour to be a masterpiece for creating memories.
            <br />
            <br />
            Embark on a journey beyond the ordinary with Connect-O-Fly, where
            every step is a gateway to unforgettable adventures.
          </Typography>
        </Grid>
        <Grid lg={5} sx={{ px: "3%" }}>
          <MoreInformation />
        </Grid>
      </Grid>
      <FooterComp />
    </Box>
  );
};

export default About;
