import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosObj from "../../Services/Api";
import { getAddSubscribeURL } from "../../Services/apiConfig";

/**
 * created a initial state
 */ 
const initialState = {
  subscribeUser: [],
  loading: false,
  error: null,
};

/**
 * Subscribe User function
 */
export const SubscribeUser = createAsyncThunk(
  "/flight_deals/api/email-subscribe/",
  async (parameters) => {
    console.log("parameters", parameters);
    try {
      const response = await axiosObj.post(getAddSubscribeURL(), parameters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
export const subscribeUserSlice = createSlice({
  name: "subscribeUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SubscribeUser.pending, (state) => {
        state.loading = true;
      })

      .addCase(SubscribeUser.fulfilled, (state, action) => {
        state.subscribeUser = action.payload;
        state.loading = false;
      })

      .addCase(SubscribeUser.rejected, (state) => {
        state.subscribeUser = [];
        state.loading = false;
      });
  },
});

export const getCreateSubscribeUser = (state) => state.subscribeUsers.subscribeUser;
export const getLoadingState = (state) => state.flightSearchOneWays.loading;

/**
 * exported the reducer
 */
export default subscribeUserSlice.reducer;
