import { Grid, Link, List, ListItem, Typography, Box } from "@mui/material";
import React from "react";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import { FiberManualRecord } from "@material-ui/icons";
import FooterComp from "../../components/FooterComp/FooterComp";
const TermsAndCondition = () => {
  return (
    <Box>
      <Grid container sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid
          lg={7}
          sx={{
            lineHeight: "25px",
            px: "3%",
            mb: "5%",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "700" }}>
            Terms & Conditions:
          </Typography>
          <br />
          <Typography variant="p">
            Welcome to the world of travel and discovery presented by
            Connect-O-Fly. Please read our terms and conditions ("Terms and
            Conditions") carefully as they govern all travel-related services
            provided by Connect-O-Fly, Inc. and its affiliates, agents,
            employees, affiliated businesses, independent contractors, or
            subcontractors (hereinafter referred to as "Connect-O-Fly," "we," or
            "us"). You can get in touch with Connect-O-Fly, Inc., a company with
            headquarters in California, at 1-844-455-5444.
            <br />
            <br />
            Travel planning, consultations, flights, cruises, lodging, ground
            transportation, tours, activities, and any other travel-related
            products are all included in the term "Service" or "Services" as
            used in these Terms and Conditions. Any offered combination of
            Services is referred to as a "Trip". The term "Supplier" or "Vendor"
            refers to companies that offer the Services we do.The terms
            "Traveler," "you," or "your" refer to individuals who are purchasing
            or considering purchasing a Trip for themselves, for others, or for
            parties taking part in a Trip.
            <br />
            <br />
            The terms and conditions of each supplier of the service that is a
            part of a Connect-O-Fly Trip are also applicable to all reservations
            for Trips. You accept and agree to abide by the Terms and Conditions
            of the applicable Suppliers by making a reservation with
            Connect-O-Fly. The terms and conditions of Connect-O-Fly will take
            priority over those of the Supplier in the event of a conflict
            regarding our obligations and liabilities.
            <br />
            <br />
            We advise you to thoroughly read and understand these Terms and
            Conditions before agreeing to them. If required, you should also ask
            for clarification and possibly seek legal advice. By checking the "I
            Agree" box at the bottom of the submission page, you are indicating
            your agreement to these Terms and Conditions. If you're making a
            reservation on behalf of someone else, your acceptance serves as
            confirmation that you've made them aware of our Terms and Conditions
            and that they've also agreed to be bound by them.
            <br />
            <br />
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            Table of Contents: Getting Around Your Trip
          </Typography>
          <List component="ol">
            <ListItem>
              1.
              <Link
                component="a"
                href="#item1"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Eligibility
              </Link>
            </ListItem>
            <ListItem>
              2.
              <Link
                component="a"
                href="#item2"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Changes to Our Terms and Conditions
              </Link>
            </ListItem>
            <ListItem>
              3.
              <Link
                component="a"
                href="#item3"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Payments
              </Link>
            </ListItem>
            <ListItem>
              4.
              <Link
                component="a"
                href="#item4"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Prices
              </Link>
            </ListItem>
            <ListItem>
              5.
              <Link
                component="a"
                href="#item5"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Modifications and Cancellations
              </Link>
            </ListItem>
            <ListItem>
              6.
              <Link
                component="a"
                href="#item6"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Best Price Guarantee
              </Link>
            </ListItem>
            <ListItem>
              7.
              <Link
                component="a"
                href="#item7"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Providing Travel Documents
              </Link>
            </ListItem>
            <ListItem>
              8.
              <Link
                component="a"
                href="#item8"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Insurance
              </Link>
            </ListItem>
            <ListItem>
              9.
              <Link
                component="a"
                href="#item9"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Passports, Visas, Health, and Travel Risks
              </Link>
            </ListItem>
            <ListItem>
              10.
              <Link
                component="a"
                href="#item10"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Accommodations & Rental Cars
              </Link>
            </ListItem>
            <ListItem>
              11.
              <Link
                component="a"
                href="#item11"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Notice
              </Link>
            </ListItem>{" "}
            <ListItem>
              12.
              <Link
                component="a"
                href="#item12"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Travel Security
              </Link>
            </ListItem>{" "}
            <ListItem>
              13.
              <Link
                component="a"
                href="#item13"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Embracing Diversity
              </Link>
            </ListItem>{" "}
            <ListItem>
              14.
              <Link
                component="a"
                href="#item14"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Responsible Conduct
              </Link>
            </ListItem>{" "}
            <ListItem>
              15.
              <Link
                component="a"
                href="#item15"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Accurate Representation and Marketing
              </Link>
            </ListItem>{" "}
            <ListItem>
              16.
              <Link
                component="a"
                href="#item16"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Limitation of Liability
              </Link>
            </ListItem>{" "}
            <ListItem>
              17.
              <Link
                component="a"
                href="#item17"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Refusal of Warranties
              </Link>
            </ListItem>{" "}
            <ListItem>
              18.
              <Link
                component="a"
                href="#item18"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Reimbursement and Release
              </Link>
            </ListItem>{" "}
            <ListItem>
              19.
              <Link
                component="a"
                href="#item19"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Major Force
              </Link>
            </ListItem>{" "}
            <ListItem>
              20.
              <Link
                component="a"
                href="#item20"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Air Transport
              </Link>
            </ListItem>{" "}
            <ListItem>
              21.
              <Link
                component="a"
                href="#item21"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Traveler hereby declares and assures that
              </Link>
            </ListItem>{" "}
            <ListItem>
              22.
              <Link
                component="a"
                href="#item22"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Medical and Physical Conditions; Medical Emergencies
              </Link>
            </ListItem>{" "}
            <ListItem>
              23.
              <Link
                component="a"
                href="#item23"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Disputes: Governing Law, Jurisdiction, etc.
              </Link>
            </ListItem>{" "}
            <ListItem>
              24.
              <Link
                component="a"
                href="#item24"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Attorney's Fees, Costs, and Expenses of Suit
              </Link>
            </ListItem>{" "}
            <ListItem>
              25.
              <Link
                component="a"
                href="#item25"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Assignment
              </Link>
            </ListItem>{" "}
            <ListItem>
              26.
              <Link
                component="a"
                href="#item26"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Severability and Survivability
              </Link>
            </ListItem>{" "}
            <ListItem>
              27.
              <Link
                component="a"
                href="#item27"
                sx={{
                  color: "black",
                  ml: "5px",
                }}
              >
                Complete Agreement, Waiver, and Other
              </Link>
            </ListItem>
          </List>
          <Typography Id="item1" variant="p">
            1.<span style={{ fontWeight: "600" }}>Eligibility:</span> US
            citizens living in US territories who are of legal age and are
            eligible to enter into and complete their duties under these Terms
            and Conditions may purchase our Services. To purchase a
            Connect-O-Fly Trip, a traveler must be at least 18 years old.
          </Typography>
          <br />
          <br />
          <Typography Id="item2" variant="p">
            2.
            <span style={{ fontWeight: "600" }}>
              Changes to Our Terms and Conditions:
            </span>{" "}
            We reserve the right to make changes or modifications to our Terms
            and Conditions at any time and without prior notice. Trips that were
            booked before the revision or modification will not be affected by
            such changes. To understand the most recent conditions, please read
            and accept our Terms and Conditions at the time of booking.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item3" variant="p">
            3.<span style={{ fontWeight: "600" }}>Payments:</span> If a balance
            is not paid in full, an outside collection agency may become
            involved. You will be liable for any costs associated with this
            procedure, including any legal expenses.
          </Typography>
          <br />
          <br />
          <Typography Id="item4" variant="p">
            4.We charge contractual tariffs as our prices. Incidentals,
            gratuity, and other things are not included in the listed price.
            Please go over each Trip's specifics.
          </Typography>{" "}
          <br /> <br />
          <Typography Id="item5" variant="p">
            5.
            <span style={{ fontWeight: "600" }}>
              Modifications and Cancellations:
            </span>{" "}
            Our regulations change depending on the Service and Trip. Prior to
            making a purchase, you can get more information by emailing or
            talking to one of our agents.
          </Typography>
          <br />
          <br />
          <Typography Id="item6" variant="p">
            6.
            <span style={{ fontWeight: "600" }}>
              Your assurance of unbeatable value is provided by the Best Price
              Guarantee:
            </span>
            <br />
            At Connect-O-Fly, we're dedicated to providing you with the best
            travel offers. You can book with confidence knowing you're getting
            the best deal possible for your trip thanks to our Best Price
            Guarantee. This is how it goes:
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "500px" }}>
              How to Claim Your Guarantee:{" "}
            </Typography>
            Contact us at service@ConnectOFly.com if, within 24 hours of making
            your reservation with Connect-O-Fly, you discover the same flight at
            a lower price somewhere else. Include a screenshot of the competing
            offer, rate information, and competitor data. You are entitled to a
            refund equal to the difference between your total payment and the
            lower price identified if you can show documentary proof of the
            lower price.
            <br />
            <Typography variant="p" sx={{ fontWeight: "500px" }}>
              Conditions for Comparison:{" "}
            </Typography>
            The lower-priced flight must meet all comparison requirements in
            terms of airline(s), flight numbers, distance travelled, stops made,
            and dates and times for both the outward and incoming legs. The same
            number of people and routes must be covered by the offer.
            <br />
            <br />
            Any modifications made to your reservation after you have made it
            are subject to the terms of the airline fare. Changes to the
            departure and arrival times of flights are available, subject to
            availability and a per-person change fee. Any price difference
            between your initial total and the cost of the new flight will also
            be considered. Please respect the deadlines set forth to prevent
            incurring additional fees.
            <br />
            <br />
            Alterations are handled as cancellations, followed by new
            reservations, with the associated fees. If you cancel or modify your
            reservation, our Suppliers may charge you fees in addition to
            administrative charges. These expenses will be deducted from your
            return in the event of cancellation in accordance with our
            agreement.
            <br />
            <br />
            Residents of California and Illinois should note that payments will
            be quickly repaid in the case of transportation or travel service
            cancellations when the Traveler is not at fault and has not
            disregarded the terms, unless the outlined circumstances apply.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Changes attributable to Connect-O-Fly:{" "}
            </Typography>{" "}
            While we strive to limit changes and cancellations, we reserve the
            right to alter or cancel reservations in the event of unavoidable
            circumstances.
            <br />
            <br />
            We'll provide you quick notice and alternate options if we have to
            make significant adjustments or cancellations.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Compensation for modifications:
            </Typography>{" "}
            If there are major modifications or cancellations prior to
            departure, we will provide alternate dates and/or itineraries while
            attempting to preserve the quality and price of your original
            reservation. Although there are few instances, you will be given
            options.
            <br />
            <br />
            Rarely, events like "force majeure" may compel adjustments or
            cancellation after departure but before the trip's conclusion.
            Refunds, reimbursement, and cost covering aren't possible in certain
            situations.
          </Typography>
          <br />
          <br />
          <Typography Id="item7" variant="p">
            7.
            <span style={{ fontWeight: "600" }}>
              Providing Travel Documents: A Step Towards Efficiency
            </span>
            <br />
            We take into account details regarding your Trip, including
            departure time and Service type, while tailoring the provision of
            travel documents and tickets. To prevent negative effects or
            non-receipt of your travel documents, it is essential that you
            submit accurate information.
            <br />
            <br />
            Security First: To ensure secure delivery, travel documents are only
            provided to the Traveler who purchased them and agreed to these
            Terms and Conditions.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item8" variant="p">
            8.
            <span style={{ fontWeight: "600" }}>
              Insurance: Your Safety Net for Calm Travels
            </span>
            <br />
            All travelers are advised to think about getting travel,
            cancellation, baggage loss, and/or medical emergency insurance.
            Insurance provides complete protection against unplanned setbacks
            and secures your travel experience.
            <br />
            <br />
            Make Informed Decisions: Although we can recommend trustworthy
            insurance companies, understanding coverage restrictions and
            decision making fall under your purview.
            <br />
            <br />
            Empower Your Journey: The right insurance gives you peace of mind,
            enabling you to face obstacles with assurance and travel without
            stress.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item9" variant="p">
            9.
            <span style={{ fontWeight: "600" }}>
              Passports, Visas, Health, and Travel Risks: Your Essential
              Preparations
            </span>
            <br />
            Here is important information to keep in mind during your
            Connect-O-Fly adventure:
            <br />
            <br />
            Make sure you are prepared for your trip by getting the appropriate
            visas, passports, and vaccinations. All of our featured
            international locations require a current passport. <br />
            <br />
            Having a passport that is valid for at least six months after your
            intended departure date is a requirement for the majority of foreign
            travel, including to India.
            <br />
            <br />
            Visa expertise If you don't have the necessary visa, you risk being
            held until you can leave again. For instance, a tourist visa is
            required for India. Entry requirements before granting a visa,
            several nations need proof of insurance, sufficient finances, and
            return tickets.
            <br />
            <br />
            The names on your travel documents must perfectly match the names on
            your passport and visas.
            <br />
            <br />
            Keep Up to Date: Visit <Link> //travel.state.gov/travel </Link> to
            get the most recent information from the State Department. Before
            leaving, research India on the website of the Indian Embassy in
            Washington, D.C. (<Link>https://www.indianembassy.org/</Link>).
            <br />
            <br />
            Utilize Connect-O-Fly to travel wisely and enjoy the experience
            stress-free and well-prepared.
            <br />
            <br />
            Vaccination Requirements: Your Health and Safety Come First <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Knowing what vaccinations, you need before travelling is
              essential. What you need to know is as follows:
            </Typography>{" "}
            Diverse Requirements: Different nations and areas have different
            immunization requirements. Consult your local health department and
            consulate for up-to-date information to stay informed.
            <br />
            <br />
            You are accountable for: Your immunization history is solely your
            responsibility. You waive Connect-O-Fly right to research or confirm
            the necessary vaccinations for any given trip.
            <br />
            <br />
            Safety and Security: You also absolve Connect-O-Fly of
            responsibility for keeping an eye on safety and security
            circumstances in locations throughout your travels or extensions,
            whether deliberate or not.
            <br />
            <br />
            <Typography variant="span" sx={{ fontWeight: "600" }}>
              Informational Sources:
            </Typography>
            <br />
            Consult the State Department at
            <Link> //travel.state.gov/travel/travel_1744.html </Link> or (202)
            647-5335 for information on travel safety and security situations.
            <br />
            Contact the U.S. Centers for Disease Control (CDC) at (404)
            332-4559, use their fax information service at (404) 332-4565, or go
            to <Link> //wwwnc.cdc.gov/travel/ </Link> for information about
            overseas health requirements and dangers. <br />
            <br />
            Travel Preparation: It is your responsibility to ensure that you
            have the proper and valid documentation, the required immunizations,
            the essential travel authorizations, and medicine confirmations for
            the countries you will be visiting. Any illness, delays, claims, or
            expenses as a result of your failure to comply with these standards
            are not our responsibility comply with these standards are not our
            responsibility. <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Your Duty to Document and Maintain Safety Comes First
            </Typography>
            <br />
            A crucial reminder for your journey with us is provided here:
            <br />
            <br />
            Entry and Transportation: We want your trip to go smoothly, but
            having the appropriate papers is essential. If you are refused
            entrance, travel on an airline, or transit or a cruise because of
            incomplete or wrong documentation, we are not liable.
            <br />
            <br />
            Financial Responsibilities: You are financially liable for any
            fines, levies, claims, or penalties brought about by inaccurate
            documentation. You'll have to defend us and pay our expenses in such
            cases.
            <br />
            <br />
            Risk awareness: While most journeys go without a hitch, some places
            carry higher hazards. Your safety is your exclusive responsibility
            anywhere.
            <br />
            <br />
            Risk Disclosure: We disclaim all liability for providing information
            on risks in your travel destinations. Your own safety is still top
            priority.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Passport & Visa Essentials: Simplified
            </Typography>
            <br />
            Your passport and visa requirements made easy:
            <br />
            <br />
            Domestic Travel:
            <br />
          
            Need valid government photo I.D. for all.
            <br />
            Minors with parents usually skip I.D.
            <br />
            Babies under 2 might need birth certificate.
            <br />
            <br />
            International Travel:
            <br />
            Passport or ID a must.
            <br />
            Get Visas, permits for entry.
            <br />
            Passport valid 6 months post return.
            <br />
            <br />
            Ticketing Note:
            <br />
            One-way? Check entry requirements.
            <br />
            <br />
            Stay informed and ready with Connect-O-Fly for smooth travels.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item10" variant="p">
            10.
            <span style={{ fontWeight: "600" }}>
              Accommodations & Rental Cars: Why Your Comfort Is Important
            </span>
            <br />
            What you need to know about where you'll stay and how you'll get
            around is as follows:
            <br />
            <br />
            Definition of Accommodations: Accommodations include a variety of
            living arrangements, including hotels, ship quarters, campers, and
            more. These are available from us via outside Vendors. These spaces
            are not managed or owned by Connect-O-Fly.
            <br />
            <br />
            Location & features: We can't guarantee the accommodations' exact
            location or features. Any problems, speak with the
            proprietors/managers of the accommodation immediately.
            <br />
            <br />
            Accommodation costs are often calculated on a double occupancy
            basis.
            <br />
            <br />
            Single Accommodations: Some hotels may charge an additional price if
            you choose a single room. The cost varies by location. For
            specifics, get in touch with us.
            <br />
            <br />
            Connect-O-Fly ensures you rest easy and move smoothly on your
            journey.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Hotel & Rental Car Policies: Simple Check-In
            </Typography>
            <br />
            For reservations at hotels and auto rentals, take note of the
            following:
            <br />
            <br />
            Booking a hotel:
            <br />
            <br />
            -Prepare a credit card and a valid photo ID.
            <br />
            -Credit card is required for hotel-specific fees or charges at
            checkout in addition to reservation guarantees.
            <br />
            -These expenses, which are not included in the nightly rate, might
            be either necessary (like resort fees) or discretionary (parking,
            calls, and minibar).
            <br />
            <br />
            Vehicle Pick-up:
            <br />
            <br />
            -The tenant must be present to sign the lease and present their
            credit card.
            <br />
            -The tenant should be the only one with access to the credit card.
            <br />
            <br />
            Travel smart with Connect-O-Fly for seamless checkins and pick-ups.
            <br />
          </Typography>
          <br />
          <br />
          <Typography Id="item11" variant="p">
            11.<span style={{ fontWeight: "600" }}>Notices:</span> Any notices
            required or permitted hereunder shall be given via email to
            <Link> Hello@ConnectOFly.com</Link>
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item12" variant="p">
            12.
            <span style={{ fontWeight: "600" }}>
              Travel Security: Keeping Your Trust <br />
            </span>
            We've got your back, so relax:
            <br />
            <br />
            California Defense:
            <br />
            <br />
            -We operate a trust account with First Tech Credit Union in
            accordance with California law.
            <br />
            -We are a member of the Travel Consumer Restitution Fund (TCRF) of
            California.
            <br />
            <br />
            Protection Information: <br />
            <br />
            -For transactions where the travel payment exceeds $300, California
            residents are covered by the TCRF. <br />
            -You are protected if the seller neglected to repay you or send
            money to a supplier. <br />
            -The maximum sum that TCRF will reimburse is $15,000 for each
            traveler. <br />
            -Claim must be filed within 12 months after the planned end of the
            trip. <br />
            -For claims, documentation and a $35 fee are necessary. <br />
            -You consent to give up other civil rights you may have against the
            seller in exchange. <br />
            -Requesting a claim form? Visit www.tcrcinfo.org to contact the
            Travel Consumer Restitution Corporation at P.O. Box 6001, Larkspur,
            California, 94977-6001. <br />
            <br />
            Non-California Transactions:
            <br />
            <br />
            Transactions outside California aren't covered by TCRF.
            <br />
            Your peace of mind matters to us at Connect-O-Fly.
          </Typography>
          <br />
          <br />
          <Typography Id="item13" variant="p">
            13.
            <span style={{ fontWeight: "600" }}>
              Embracing Diversity: Understanding Local Laws and Customs
            </span>
            <br />
            You are exposed to many obstacles, rules, and customs when you go to
            other countries. Be ready for the unexpected and observe regional
            customs. Be mindful of potential cultural differences and learn to
            adapt to different climates. As Connect-O-Fly is not liable for any
            legal concerns that may emerge, it is crucial to abide by local laws
            and customs. Immerse yourself in other cultures to enhance your
            travels.
            <br />
            <br />
            -Recognize the distinctiveness of each place you visit and embrace
            it as you travel.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item14" variant="p">
            14.
            <span style={{ fontWeight: "600" }}>
              Responsible Conduct: Abiding by the Law and the Standards of
              Conduct{" "}
            </span>
            <br />
            If your actions interfere with the interests, safety, or well-being
            of other Travelers, service providers, or local laws, your
            participation in the Trip may be terminated. You are responsible for
            planning and paying for alternate travel and lodging in the event
            that this happens. To ensure a smooth and enjoyable voyage for
            everyone concerned, abide by the law and act appropriately.
          </Typography>
          <br />
          <br />
          <Typography Id="item15" variant="p">
            15.
            <span style={{ fontWeight: "600" }}>
              Accurate Representation and Marketing:
            </span>
            <br />
            Although they serve as examples rather than legally binding
            commitments, Connect-O-Fly tries to accurately represent its
            services through realistic photos and descriptions. While we make
            every effort to include reliable travel information on our website
            and in our printed materials including lodging, activities, and food
            options some information may not be validated or up to date. As a
            result, before making choices, you should independently check the
            information. By participating, Travelers agree to this non-revocable
            use of their words, pictures, or likenesses for promotional purposes
            without payment.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item16" variant="p">
            16.
            <span style={{ fontWeight: "600" }}>Limitation of Liability: </span>
            <br />
            Even if we were made aware of the probability of such damages,
            Connect-O-Fly shall not be liable for any indirect, punitive,
            special, incidental, or consequential damages, including monetary
            loss or missed opportunities. The Traveler's total payment to
            Connect-O-Fly will be the maximum amount of our responsibility under
            this agreement. Connect-O-Fly acts only as an agent for the
            third-party providers of the services and lodging; it does not own
            or manage any of these businesses. As a result, we expressly
            disclaim responsibility for any harm, loss, damage, or delay brought
            on by the actions or inactions of third parties, such as suppliers
            and governmental bodies. Additionally, even if we were made aware of
            the possibility of such damages, Connect-O-Fly is not liable for
            losses brought on by your own actions.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item17" variant="p">
            17.<span style={{ fontWeight: "600" }}>Refusal of Warranties:</span>
            <br />
            All products and services offered by Connect-O-Fly are provided "as
            is" and "as available" unless otherwise specified otherwise. To the
            fullest extent permitted by applicable law, Connect-O-Fly expressly
            disclaims all representations and warranties, whether express or
            implied, for all products and services sold through Connect-O-Fly,
            including but not limited to warranties of merchantability, fitness
            for a particular purpose, title, uninterrupted service, and any
            warranties resulting from course of performance, dealing, or trade
            usage. Please be aware that depending on local legislation, the
            exclusion of implied warranties may not be applicable in your
            jurisdiction.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item18" variant="p">
            18.
            <span style={{ fontWeight: "600" }}>
              Reimbursement and Release
            </span>{" "}<br/>
            Except as otherwise provided herein, Traveler releases Connect-O-Fly
            from any and all liability, loss, expense, damages, or claims
            arising from Traveler's participation in a Trip, regardless of
            whether such liability arises from Traveler, Service Providers,
            Accommodation providers, third parties, or other causes, including
            negligence, intentional wrongdoing, reckless behavior, or other
            factors. <br />
            <br />
            Additionally, Traveler agrees to hold Connect-O-Fly harmless from
            any claims made against Connect-O-Fly relating to Traveler's
            participation in a Trip or Traveler's performance of this Agreement
            and to indemnify, defend, and hold Connect-O-Fly harmless from all
            damages, losses, claims, liabilities, deficiencies, costs, fees
            (including reasonable attorneys' fees), and expenses arising
            therefrom.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item19" variant="p">
            19.<span style={{ fontWeight: "600" }}>Major Force</span> <br />
            If Connect-O-Fly fails to carry out its obligations under this
            Agreement while performance is hampered or delayed by a Force
            Majeure, Connect-O-Fly will not be held accountable. "Force Majeure"
            refers to situations that are beyond Connect-O-Fly's reasonable
            control, including extreme weather, fires, floods, earthquakes, war,
            labour disputes, strikes, epidemics, World Health Organization
            advisories, Centre for Disease Control alerts, U.S. State Department
            advisories, orders from the government, power outages, terrorism,
            and any other situations that are deemed a risk to the safety and
            wellbeing of Travelers. Due to Force Majeure, Connect-O-Fly reserves
            the right to cancel any Services listed in a trip itinerary.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item20" variant="p">
            20.
            <span style={{ fontsize: "26px", fontWeight: "600" }}>
              AIR TRANSPORT:
            </span>
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Key Points for Air Travel:
            </Typography>
            <br />
            <br />
            -Connect-O-Fly responsibilities are governed by airlines' conditions
            of carriage. <br />
            <br />
            -Airlines fulfilling the contract may change from those mentioned in
            our materials. <br />
            <br />
            -Aircraft type cannot be specified by Connect-O-Fly. <br />
            <br />
            -Losses due to flight changes or cancellations are not
            Connect-O-Fly's responsibility. <br />
            <br />
            -Airlines can adjust flight times and schedules, impacting
            contractual agreements. <br />
            <br />
            -Changing airlines, aircraft types, or destinations doesn't allow
            penalty-free cancellations. <br />
            <br />
            -Work with airlines for solutions in case of flight cancellations or
            delays. <br />
            <br />
            -Connect-O-Fly won't refund missed trips due to flight
            irregularities. <br />
            <br />
            -Direct flights can involve non-stop or single-stop flights with the
            same flight number. <br />
            <br />
            -Adequate time is crucial for connecting flights from different
            airports.
            <br />
            <br />
            -Connect-O-Fly isn't liable for missed connecting flights' expenses.{" "}
            <br /> <br />
            -Force majeure events and failure to comply with requirements aren't
            Connect-O-Fly's liability.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Problems with E-Ticket Issuance:
            </Typography>
            <br />
            <br />
            -Since June 1st, 2008, IATA rules require e-ticket issuance for air
            travel. <br /> <br />
            -Airlines and agencies must issue tickets electronically, known as
            "e-tickets." <br /> <br />
            -Some technical limitations (infants, agreements, groups) may
            prevent e-ticket issuance. <br /> <br />
            -Availability of flights doesn't guarantee e-ticket issuance due to
            constraints. <br /> <br />
            -Such situations beyond our control won't make us liable. <br />{" "}
            <br />
            -If e-ticket can't be issued, we'll suggest alternative route
            solutions. <br /> <br />
            -Alternatives might involve different tariffs or additional costs.{" "}
            <br /> <br />
            -If no alternative works, refusal to pay tariff difference may lead
            to cancellation. <br /> <br />
            -Cancellation due to e-ticket constraints won't cost you anything.
            <br /> <br />
            -A full refund will be provided within 30 days if no alternative is
            possible.
            <br /> <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Failure to check-in:
            </Typography>
            <br />
            -Failure to check-in for the outbound flight (scheduled or charter)
            will lead to automatic cancellation of the return flight by the
            airline.
            <br /> <br />
            -To retain the possibility of keeping the return flight open, you
            should contact us on the departure date; however, the decision
            ultimately rests with the airline's discretion.
            <br /> <br />
            -It's crucial to understand that any instances of interrupted or
            shortened journeys, as well as services not utilized, do not qualify
            for a refund.
            <br /> <br />
            -If you have acquired insurance coverage, particularly for trip
            interruption, you must adhere to the cancellation procedures
            outlined in your insurance contract to ensure compliance.
            <br />
            <br />{" "}
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Flight connections:
            </Typography>
            <br />
            -In case a connecting flight related to your departure or return
            flight is canceled or delayed, the airlines have the authority to
            offer alternative transportation methods (e.g., coach/bus, train).
            <br /> <br />
            -If you personally arrange your own connecting transportation while
            utilizing Connect-O-Fly services, it's recommended to book flexible
            or refundable tickets to mitigate potential financial setbacks.
            <br /> <br />
            -It's prudent to avoid scheduling significant appointments for the
            day following your return date.
            <br /> <br />
            -Connect-O-Fly holds no liability for the outcomes of delays,
            including canceled scheduled flights, regarding independently
            organized connecting transport.
            <br /> <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              The Return:
            </Typography>
            <br />
            For all types of flights, whether scheduled or charter, it's crucial
            to locally re-confirm your return flight with the airline within 72
            hours before your intended departure date.
            <br />
            <br />
            Local representatives or agents usually handle this process for
            package trips.
            <br />
            <br />
            It's important to note that this re-confirmation is mandatory. If
            you neglect to do so, the airline might not guarantee your seat and
            could assign it to another passenger.
            <br />
            <br />
            This process also allows you to verify any changes to the timing of
            your return flight that might have occurred since your original
            booking.
            <br />
            <br />
            Connect-O-Fly holds no responsibility for any oversight on your part
            in failing to re-confirm your return flight.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Luggage:
            </Typography>
            <br />
            -The loss or damage to your luggage or other personal possessions
            while travelling to, from, or during a Trip is not the
            responsibility of Connect-O-Fly.
            <br />
            <br />
            -The compensation outlined in relevant international accords and
            laws holds the airline liable for your entrusted baggage.
            <br />
            <br />
            -It is advised to get in touch with your airline as soon as possible
            at the airport to report any baggagerelated problems, including
            damage, delays, theft, or loss.
            <br />
            <br />
            -Bring the required paperwork, which should include your ticket for
            the trip, a declaration, and a bag check in sheet.
            <br />
            <br />
            -For further security, think about getting an insurance policy that
            includes the value of your possessions.
            <br />
            <br />
            -Many airlines have their own baggage regulations, which may include
            weight limits and extra charges for checked bags. It is advised to
            inquire about these specifics in advance with your airline.
            <br />
            <br />
            -You are responsible for paying any additional fees associated with
            excessive or overweight luggage, such as golf bags. You will be
            required to pay an additional cost to the airline at the airport if
            your weight exceeds the maximum stated by the carrier and excess
            weight is permitted.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Babies and infants:
            </Typography>
            <br />
            -Babies under 2 years old are not allotted a separate seat; their
            ticket price is typically around 10% of the official rate.
            <br />
            <br />
            -Some flights offer discounts for children aged 2 to 11, but this
            may not apply to charter flights.
            <br />
            <br />
            <Typography variant="p" sx={{ fontWeight: "600" }}>
              Pregnancy:
            </Typography>
            <br />
            -Pregnant women intending to fly should be aware that different
            airlines have varying restrictions. These restrictions could range
            from not allowing flying within 7 to 30 days of the due date.
            <br />
            <br />
            -You are responsible for confirming and adhering to the specific
            airline's pregnancy-related guidelines.
            <br />
            <br />
            -If you are denied boarding due to not meeting the airline's
            pregnancy guidelines, any resulting cancellation fees or charges
            will not be the responsibility of Connect-O-Fly.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item21" variant="p">
            21.
            <span style={{ fontWeight: "600" }}>
              Traveler hereby declares and assures that:
            </span>{" "}
            <br /> 1.Traveler possesses the necessary authority and capability
            to enter into and fulfil the obligations stipulated in these Terms
            and Conditions.
            <br />
            <br />
            2.Traveler will adhere to all applicable laws, regulations, and
            rules, and will make sincere efforts to respect local customs during
            the Trip.
            <br />
            <br />
            3.All information supplied to Connect-O-Fly for the purpose of
            arranging the Trip is entirely truthful and precise. By these
            assertions, Traveler consents to Connect-O-Fly’s discretion to
            conduct investigations as they deem necessary to assess Traveler's
            eligibility, at their sole discretion.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item22" variant="p">
            22.
            <span style={{ fontWeight: "600" }}>
              Medical and Physical Conditions; Medical Emergencies:
            </span>
            <br />
            1.Physical Preparedness: Certain activities offered on
            Connect-O-Fly's Trips require a good level of physical fitness and
            health. Engaging in physically active and interactive pursuits
            demands participants to be in suitable physical condition. For
            water-related activities like snorkelling, jet skiing, and surfing,
            skills such as swimming, coordination, balance, and awareness of
            surroundings are vital. Travelers commit to abstain from consuming
            alcoholic beverages or substances that may hinder their physical or
            mental abilities before participating in such activities during the
            Trip.
            <br />
            <br />
            2.Personal Health Responsibility: Travelers affirm their
            responsibility for managing personal medication and medical,
            physical, or allergic conditions throughout the Trip. Connect-O-Fly
            cannot be held accountable for injuries sustained or aggravation of
            medical conditions. While medical attention may be required in case
            of an emergency, Connect-O-Fly is not obligated to facilitate or aid
            in treatment. Quality of medical facilities and professionals varies
            by location and is beyond Connect-O-Fly’s control. In areas with
            limited medical care, evacuation might be prolonged and costly.
            Connect-O-Fly is not liable for the costs of medical treatment and
            assumes no responsibility for the provision or lack of medical care
            you receive during the Trip.
            <br />
            <br />
            3.Release from Liability: Traveler releases Connect-O-Fly from
            liability concerning medical care, whether arranged by a Service
            provider or Connect-O-Fly. Traveler agrees to bear the expenses
            associated with any necessary medical treatments during the Trip.
            You are responsible for the risks and costs associated with any
            medical care needed during the Trip.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item23" variant="p">
            23.
            <span style={{ fontWeight: "600" }}>
              Disputes: Governing Law, Jurisdiction, etc.:
            </span>
            <br />
            These Terms and Conditions and the relationship between Traveler and
            Connect-O-Fly are governed by the laws of the State of California,
            without regard to its conflict of law provisions. Both Traveler and
            Connect-O-Fly consent to the exclusive jurisdiction of federal and
            state courts situated in Santa Clara County, California for any
            legal proceedings related to a Trip, these Terms and Conditions, our
            Privacy Policy, Connect-O-Fly’s website, or any related literature.
            Traveler and Connect-O-Fly mutually agree to irrevocably accept the
            jurisdiction of such courts in any action, suit, or proceeding and
            waive any claims asserting personal non-jurisdiction, improper
            venue, or unenforceability of this agreement or its subject matter
            in such a court.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item24" variant="p">
            24.
            <span style={{ fontWeight: "600" }}>
              Attorney's Fees, Costs, and Expenses of Suit:
            </span>
            <br />
            In the event that legal action, whether through litigation or
            arbitration, is initiated to enforce, interpret, or construe the
            terms of these Terms and Conditions, a Trip, our Privacy Policy,
            Connect-O-Fly’s website, or any related literature, the party that
            prevails in such action shall have the right to recover reasonable
            attorney’s fees, actual costs, and expenses incurred in connection
            with the legal proceedings.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item25" variant="p">
            25.<span style={{ fontWeight: "600" }}>Assignment:</span> <br />
            Without Traveler's prior written agreement, Traveller is not allowed
            to transfer or assign any of their rights or responsibilities under
            these Terms and Conditions.
          </Typography>{" "}
          <br />
          <br />
          <Typography Id="item26" variant="p">
            26.
            <span style={{ fontWeight: "600" }}>
              Severability and Survivability:
            </span>
            <br />
            The validity and enforceability of the remaining terms shall not be
            impacted in any way if any provision or portion of a provision in
            these Terms and Conditions is determined to be invalid, void, or
            unenforceable for any reason. In such circumstances, Traveller and
            Connect-O-Fly agree to substitute a legitimate provision that most
            closely approximates the meaning and effects of the invalid
            provision.
            <br />
            <br />
            Any clause in these terms and conditions that imposes ongoing
            obligations on a party shall remain enforceable even after its
            conclusion or termination, notwithstanding any other clauses in
            these terms and conditions or contradictory legal principles. <br />
            <br />{" "}
          </Typography>{" "}
          <Typography Id="item27" variant="p">
            27.
            <span style={{ fontWeight: "600" }}>
              Complete Agreement, Waiver, and Other:
            </span>
            <br />
            These Terms and Conditions supersede any former or concurrent
            understandings and agreements, whether written or oral, regarding
            the issues they cover by enshrining the entire understanding and
            agreement of the parties thereto. The terms set out above shall not
            be modified by any terms contained in any proposal, purchase order,
            acknowledgment, or other document. Any delay or failure on the side
            of either party to exercise or enforce a right or provision under
            these terms will not be seen as a waiver of that right or provision.
            Each party will continue to be able to use or enforce all of the
            rights and clauses in this agreement. No one waiver shall constitute
            a continuing or further waiver. It is not a guarantee made by
            Connect-O-Fly that it will deal with every violation of these terms
            and conditions. Any modification, change, or waiver of any provision
            of this agreement must be made in writing and signed by both parties
            in order to be effective.
          </Typography>{" "}
        </Grid>
        <Grid lg={5} sx={{ px: "3%" }}>
          <MoreInformation />
        </Grid>
      </Grid>
      <FooterComp />
    </Box>
  );
};

export default TermsAndCondition;
