import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

function Travelers({
  adultsCount,
  childrenCount,
  infantsCount,
  selectedOption,
  onAdultsChange,
  onChildrenChange,
  onInfantsChange,
  onSelectedOptionChange,
}) {
  const [validationMessage, setValidationMessage] = useState("");
  const [validationColor, setValidationColor] = useState("red");
  const [showForm, setShowForm] = useState(false);

  const [searchParams] = useSearchParams();

  const adultsParam = searchParams.get("number_of_adults");
  const childrenParam = searchParams.get("number_of_childrens");
  const infantsParam = searchParams.get("number_of_infants");

  const adultsParamInt = parseInt(adultsParam);
  let adults = adultsParamInt ? adultsParamInt : parseInt(adultsCount);
  if(adultsParamInt !== parseInt(adultsCount)){
    adults = parseInt(adultsCount);
  }

  const childrenParamInt = parseInt(childrenParam);
  let children = childrenParamInt ? childrenParamInt : parseInt(childrenCount);
  if(childrenParamInt !== parseInt(childrenCount)){
    children = parseInt(childrenCount);
  }

  const infantsParamInt = parseInt(infantsParam);
  let infants = infantsParamInt ? infantsParamInt : parseInt(infantsCount);
  if(infantsParamInt !== parseInt(infantsCount)){
    infants = parseInt(infantsCount);
  }

  const handleIncrement = (type) => {
    if (adults + children + infants >= 9) {
      setValidationMessage("Maximum 9 Travellers are allowed!");
      setValidationColor("red");
      return;
    }

    switch (type) {
      case "adults":
        onAdultsChange(adults + 1);
        break;
      case "children":
        onChildrenChange(children + 1);
        break;
      case "infants":
        onInfantsChange(infants + 1);
        break;
      default:
        break;
    }
    setValidationColor("green");
  };

  const handleDecrement = (type) => {
    let newValue;

    switch (type) {
      case "adults":
        newValue = adults - 1 >= 1 ? adults - 1 : adults;
        onAdultsChange(newValue);
        break;
      case "children":
        newValue = children - 1 >= 0 ? children - 1 : children;
        onChildrenChange(newValue);
        break;
      case "infants":
        newValue = infants - 1 >= 0 ? infants - 1 : infants;
        onInfantsChange(newValue);
        break;
      default:
        break;
    }

    setValidationMessage("");
  };

  const handleChange = (event) => {
    onSelectedOptionChange(event.target.value);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const updateSelectedData = () => {
    const totalTravelers = adults + children + infants;
    const data = `${totalTravelers} Travelers, ${selectedOption}`;
    return data;
  };

   //when click anywhere form closed
   const formRef = useRef(null);
 
   useEffect(() => {
     const handleClickOutside = (event) => {
       if (formRef.current && !formRef.current.contains(event.target)) {
         setShowForm(false);
       }
     };
     document.addEventListener("click", handleClickOutside);
     return () => {
       document.removeEventListener("click", handleClickOutside);
     };
   }, []); 

  return (
    <form ref={formRef}>
      <TextField
        placeholder="1 traveler, Economy"
        variant="outlined"
        fullWidth
        value={updateSelectedData()}
        onClick={() => setShowForm(true)}
        InputProps={{ readOnly: true, style: { fontSize: "14px" } }}
      />
      {showForm && (
        <Grid
          container
          sx={{
            p: "5% 2%",
            width: "100%",
            height: "auto",
            position: "absolute",
            backgroundColor: "white",
            zIndex: "2",
            px: {
              lg: "5% 2%",
              md: "5% 8%",
              sm: "10%",
              xs: "5%",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              component="p"
              sx={{ fontWeight: "700", fontSize: "18px" }}
            >
              Travelers
            </Typography>
          </Grid>
          <Grid container sx={{ marginBottom: "5px" }}>
            <Grid item xs={6} sm={6} md={6}>
              <Typography component="p" sx={{ fontSize: "12px" }}>
                Adults
              </Typography>
              <Typography component="p" sx={{ fontSize: "10px" }}>
                12 + years
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleDecrement("adults")}
              >
                -
              </Button>
              <Typography variant="span" sx={{ px: "10px" }}>
                <span>{adults}</span>
              </Typography>

              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleIncrement("adults")}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "5px" }}>
            <Grid xs={6} sm={6} md={6}>
              <Typography component="p" sx={{ fontSize: "12px" }}>
                Children
              </Typography>
              <Typography component="p" sx={{ fontSize: "10px" }}>
                2-11 years
              </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6}>
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleDecrement("children")}
              >
                -
              </Button>
              <Typography variant="span" sx={{ px: "10px" }}>
                <span>{children}</span>
              </Typography>

              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleIncrement("children")}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "5px" }}>
            <Grid xs={6} sm={6} md={6}>
              <Typography component="p" sx={{ fontSize: "12px" }}>
                Infants
              </Typography>
              <Typography component="p" sx={{ fontSize: "10px" }}>
                0-2 years
              </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6}>
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleDecrement("infants")}
              >
                -
              </Button>
              <Typography variant="span" sx={{ px: "10px" }}>
                <span>{infants}</span>
              </Typography>
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#eff0f6",
                  borderRadius: "4px",
                  minWidth: "24px",
                }}
                onClick={() => handleIncrement("infants")}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                component="p"
                sx={{ fontWeight: "700", fontSize: "18px" }}
              >
                Cabin class
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth required>
                <FormControlLabel
                  value="Economy"
                  sx={{
                    width: "100%",
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      sx={{
                        padding: 0,
                        width: "30px",
                      }}
                      checked={selectedOption === "Economy"}
                      onChange={handleChange}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>Economy</span>}
                />
                <FormControlLabel
                  value="Premium economy"
                  sx={{
                    width: "100%",
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      sx={{
                        padding: 0,
                        width: "30px",
                      }}
                      checked={selectedOption === "Premium economy"}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <span style={{ fontSize: "12px" }}>Premium economy</span>
                  }
                />
                <FormControlLabel
                  value="Business"
                  sx={{
                    width: "100%",
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      sx={{
                        padding: 0,
                        width: "30px",
                      }}
                      checked={selectedOption === "Business"}
                      onChange={handleChange}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>Business</span>}
                />
                <FormControlLabel
                  value="First"
                  sx={{
                    width: "100%",
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      sx={{ padding: 0, width: "30px" }}
                      checked={selectedOption === "First"}
                      onChange={handleChange}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>First</span>}
                />
              </FormControl>
            </Grid>
          </Grid>

          {validationMessage && (
            <p style={{ color: validationColor }}>{validationMessage}</p>
          )}
          <Button
            variant="contained"
            sx={{ width: "100%", overflow: "hidden", marginTop: "16px" }}
            onClick={handleCloseForm}
          >
            Done
          </Button>
        </Grid>
      )}
    </form>
  );
}

export default Travelers;
