import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const UpdateForm = () => {
  return (
    <Box sx={{border:"1px solid grey",backgroundColor:"white" , width: "40%",m:"2% 30%", py:"2%"}}>
    <Grid container textAlign="center" spacing={2}  width= "500"
    height="300"  >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5">Update Data</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{mx:"5%"}}> <TextField
     fullWidth required
      type="text"
    
      placeholder="Title"
     
    /></Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{mx:"5%"}}>
        <Editor
        fullWidth required
          textareaName="description"
          initialValue="<p>Description</p>"
          init={{
          
            height: 200,
            menubar: false,
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | ",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}> <Button type="submit" variant="contained">Submit</Button></Grid>
     
    </Grid></Box>
  );
};
export default UpdateForm;
