import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import trustpilotimg from "../../assets/images/trustpilotimg.png";
import { Link } from "react-router-dom";

const MoreInformation = () => {
  return (
    <Box sx={{ pl:{ lg:"10%",md:"0%"}, backgroundColor: "white" }}>
      <Typography variant="h5">More Information</Typography>
      <br />
      <Link
        to="/about-us"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        About us
      </Link>
      <br />
      <Link
        to="/affiliates"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Affiliates
      </Link>
      <br />
      <Link
        to="/terms-and-condition"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Terms And Conditions
      </Link>
      <br />
      <Link
        to="/out-culture"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Our Culture
      </Link>
      <br />
      <Link
        to="/refer-and-earn"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Refer & Earn
      </Link>
      <br />
      <Link
        to="/contact-us"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Contact Us
      </Link>
      <br />
      <Link
        to="/privacy-policy"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Privacy Policy
      </Link>
      <br />
      <Link
        to="/booking-rules"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Booking Rules
      </Link>
      <br />
      <Link
        to="/updates"
        style={{ textDecoration: "none", color: "#1879c0", lineHeight: "30px" }}
      >
        Updates
      </Link>
      <br />
      <br />
      <Typography variant="p" sx={{ fontWeight: "600", fontStyle: "24px" }}>
        Need help?
      </Typography>
      <br />
      <br />
      <Typography variant="p">
        Our team of professional travel experts are ready to help 24/7. Call us
        toll free:
      </Typography>
      <br /> <br />
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="span"
          sx={{
            p: "2px",
            pt: "5px",
            backgroundColor: "orange",
            borderRadius: "50%",
          }}
        >
          <CallRoundedIcon style={{ color: "white", width: "30px" }} />
        </Typography>
        <Typography
          variant="p"
          sx={{ color: "orange", m: "1%", fontWeight: "700" }}
        >
          1-234-456-7890
        </Typography>
      </Box>
    </Box>
  );
};

export default MoreInformation;
