const baseURL = process.env.REACT_APP_FLEIGHT_BOOKING_BASE_URL;

/**
 * Flight Booking API URL's
 */

export const getCreateFlightSearchOneWayURL = () => `${baseURL}/flight_tickets/api/oneway-trip/`;
export const getCreateFlightSearchRoundTripURL = () => `${baseURL}/flight_tickets/api/round-trip/`;
export const getAirportListURL = (search) => `${baseURL}/flight_tickets/api/get-airports-list/?search=${search}`;
export const getCreateMultiTripURL = () => `${baseURL}/flight_tickets/api/multi-trip/`;
export const getCityTownDetailsURL = () => `${baseURL}/flight_deals/api/view-citytown-list/`;
export const getFlightDealsURL = () => `${baseURL}/flight_deals/api/view-flight-deals-list/`;
export const getCustomerSliderURL = () => `${baseURL}/flight_deals/view-review-list/`;
export const getAddSubscribeURL = () => `${baseURL}/flight_deals/api/email-subscribe/`;