import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
const CheapFlightComp = ({airlineData}) => {
  return (
    <>
      <Grid item sx={12} md={4} style={{ borderRight: "1px solid #eff0f6", padding: "5px", textAlign: "center" }}>
        <Link href="#" underline="none">
          Cheapest
        </Link>
        <Typography variant="body2">$125.98</Typography>
      </Grid>
      <Grid item sx={12} md={4} style={{ borderRight: "1px solid #eff0f6", padding: "5px", textAlign: "center" }}>
        <Link href="#" underline="none">
          Quickest
        </Link>
        <Typography variant="body2">$165.88</Typography>
      </Grid>
      <Grid item sx={12} md={4} style={{ padding: "5px", textAlign: "center" }}>
        <Link href="#" underline="none">
          Best
        </Link>
        <Typography variant="body2">$125.98</Typography>
      </Grid>
    </>
  );
};

export default CheapFlightComp;
