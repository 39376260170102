import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_FLEIGHT_BOOKING_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * interceptors, axios and authorization logic here
 */

const axiosObj = {
  get: axiosInstance.get,
  post: (url, data) => axiosInstance.post(url, data),
  put: (url, data) => axiosInstance.put(url, data),
  delete: axiosInstance.delete,
};

export default axiosObj; 
