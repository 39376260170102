import React from "react";
import { Route, Routes } from "react-router-dom";
import Flights from "../pages/Flights/Flights";
import ReferEarn from "../pages/ReferEarn/ReferEarn";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import OurCulture from "../pages/OurCulture/OurCultutre";
import Updates from "../pages/Updates/Updates";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";
import Affiliates from "../pages/Affiliates/Affiliates";
import BookingRule from "../pages/BookingRule/BookingRule";
import About from "../pages/AbousUs/About";
import Contact from "../pages/ContactUs/Contact";
import Corporate from "../pages/CorporateTravel/CorporateTravel";
import GroupTravel from "../pages/GroupTravel/GroupTravel";
import CheapFlightDeal from "../components/CheapFlightDeal/CheapFlightDeal";
import CstmerAdminForm from "../AdminForms/CustomerAdminForm/CstmerAdminForm";
import SubscriberAdminForm from "../AdminForms/SubscriberAdminFoem/SubscriberAdminForm";
import FlighDealsForm from "../AdminForms/FlightDealsForm/FlightDealsForm";
import UpdateForm from "../AdminForms/UpdateForm/UpdateForm";
import BookingFlights from "../components/BookingFlights/BookingFlights";
import AdminProfileForm from "../AdminForms/AdminProfileForm/AdminProfileForm";
import CheaperFlightDeals from "../pages/CheaperFlightDeals/CheaperFlightDeals";
import PageNotFound from "../components/PageNotFound/PageNotFound";

const RouteManager = () => {
  return (
    <Routes>
      <Route index path="/" element={<Flights />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route path="/updates" element={<Updates />} />
      <Route path="/refer-and-earn" element={<ReferEarn />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/out-culture" element={<OurCulture />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/affiliates" element={<Affiliates />} />
      <Route path="/booking-rules" element={<BookingRule />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/corporate-travel" element={<Corporate />} />
      <Route path="/group-travel" element={<GroupTravel />} />
      <Route path="/cheap-flight-deal" element={<CheapFlightDeal />} />
      <Route path="/cstmer-admin-form" element={<CstmerAdminForm />} />
      <Route path="/subscriber-admin-form" element={<SubscriberAdminForm />} />
      <Route path="/admin-flight-deals-form" element={<FlighDealsForm />} />
      <Route path="/admin-update-form" element={<UpdateForm />} />
      <Route path="/booking-flight" element={<BookingFlights />} />
      <Route path="/admin-profile-form" element={<AdminProfileForm />} />
      <Route path="/cheaper-flight-deals" element={<CheaperFlightDeals />} />
      <Route path="/abc" element={<PageNotFound/>}/>
    </Routes>
  );
};

export default RouteManager;
