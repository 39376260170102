import { configureStore } from "@reduxjs/toolkit";
import exampleListSlice from "../Reducer/flightDealReducer";
import userDetail from "../Reducer/cutstomerSliderReducer";
import cityTownListDetail from "../Reducer/flightDealsCitytownReducer";
import flightBookingTicketReducer from "../Reducer/flightBookingTicketReducer";
import flightSearchRoundWayReducer from "../Reducer/flightSearchRoundWayReducer"
import flightSearchMultiTripReducer from "../Reducer/flightSearchMultiTripReducer";
import originDestinationFlightListReducer from "../Reducer/originDestinationFlightListReducer";
import subscribeReducer from "../Reducer/subscribeReducer";

export const store = configureStore({
  reducer: {
    app: exampleListSlice, //comp API data used for display data
    cityTown: cityTownListDetail, //comp API data used for navbar
    app1: userDetail, //comp customSlider 
    flightSearchOneWays: flightBookingTicketReducer, //comp flight used for post data
    roundWayDetails : flightSearchRoundWayReducer,
    multiTripDetails: flightSearchMultiTripReducer,
    airportLists: originDestinationFlightListReducer,
    subscribeUsers: subscribeReducer
  },
});
