import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
  Typography,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import data from "../../JsonFiles/OneWayTrip/OneWayTrip.json";

const CheapFlightFilter = ({ onFilter }) => {
  const [stopoverCode, setStopoverCode] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [dropdownIcon, setDropdownIcon] = useState("down");
  const [durationDropdownOpen, setDurationDropdownOpen] = useState(true);
  const [durationRange, setDurationRange] = useState([0, 1440]);
  const [timeRange, setTimeRange] = useState([0, 1440]);
  const [timeDropdownOpen, setTimeDropdownOpen] = useState(true);
  const [filteredFlights, setFilteredFlights] = useState([]);

  
  //row1
  const handleOptionChange = (event) => {
    const newStopoverCode = event.target.value;
    setStopoverCode(newStopoverCode);
    filterFlights(newStopoverCode, durationRange, timeRange);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setDropdownIcon(dropdownOpen ? "down" : "up");
  };

  //row2
  const handleDurationChange = (event, newValue) => {
    setDurationRange(newValue);
    filterFlights(stopoverCode, newValue, timeRange);
  };
  const toggleDurationDropdown = () => {
    setDurationDropdownOpen(!durationDropdownOpen);
    setDropdownIcon(durationDropdownOpen ? "down" : "up");
  };

  //row3
  const handleTimeChange = (event, newValue) => {
    setTimeRange(newValue);
    filterFlights(stopoverCode, durationRange, newValue);
  };
  const toggleTimeDropdown = () => {
    setTimeDropdownOpen(!timeDropdownOpen);
    setDropdownIcon(timeDropdownOpen ? "down" : "up");
  };

  //filter
  const filterFlights = (
    selectedStopoverCode,
    selectedDurationRange,
    selectedTimeRange
  ) => {
    const allLegs = data.data.legs;
    const filteredFlights = [];

    allLegs.forEach((leg) => {
      if (
        selectedStopoverCode === "ANY" ||
        leg.stopoverCode === selectedStopoverCode
      ) {
        const legDuration = leg.durationMinutes;
        const departureTimeMinutes = leg.departureTimeMinutes;
        const arrivalTimeMinutes = leg.arrivalTimeMinutes;

        if (
          legDuration >= selectedDurationRange[0] &&
          legDuration <= selectedDurationRange[1] &&
          isTimeInRange(departureTimeMinutes, selectedTimeRange) &&
          isTimeInRange(arrivalTimeMinutes, selectedTimeRange)
        ) {
          filteredFlights.push(leg);
        }
      }
    });

    setFilteredFlights(filteredFlights);
    onFilter(filteredFlights);
  };

  const isTimeInRange = (timeMinutes, selectedTimeRange) => {
    const [startTime, endTime] = selectedTimeRange;
    return timeMinutes >= startTime && timeMinutes <= endTime;
  };

  //submit
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Filtered Flights:", filteredFlights);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: "5%" }}>
        <Box className="row1">
          <Box onClick={toggleDropdown} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Stops</Typography>
            <Typography sx={{ cursor: "pointer" }}>
              {dropdownIcon === "down" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </Typography>
          </Box>
          {dropdownOpen && (
            <FormControl>
              <RadioGroup value={stopoverCode} onChange={handleOptionChange}>
                <FormControlLabel
                  value="DIRECT"
                  control={<Radio />}
                  label="Direct only"
                />
                <FormControlLabel
                  value="UPTOONESTOP"
                  control={<Radio />}
                  label="Up to 1 stop"
                />
                <FormControlLabel 
                  value="ANY"
                  control={<Radio />}
                  label="Any" />
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        <Box className="row2">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Duration</Typography>
            <Typography
              onClick={toggleDurationDropdown}
              sx={{ cursor: "pointer" }}
            >
              {dropdownIcon === "down" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </Typography>
          </Box>
          {durationDropdownOpen && (
            <Slider
              value={durationRange}
              onChange={handleDurationChange}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                const hours = Math.floor(value / 60);
                const minutes = value % 60;
                return `${hours}h ${minutes}m`;
              }}
              max={1440}
            />
          )}
        </Box>
        <Box className="row3">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Time</Typography>
            <Typography onClick={toggleTimeDropdown} sx={{ cursor: "pointer" }}>
              {dropdownIcon === "down" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </Typography>
          </Box>
          {timeDropdownOpen && (
            <Slider
              value={timeRange}
              onChange={handleTimeChange}
              valueLabelDisplay="auto"
              min={0}
              max={1440}
              valueLabelFormat={(value) => {
                const hours = Math.floor(value / 60);
                const minutes = value % 60;
                return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
              }}
            />
          )}
        </Box>

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default CheapFlightFilter;