import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const FlighDealsForm = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [inputvalue, setInputValue] = useState({
    originAirport: "",
    destinationAirport: "",
    startDate: "",
    endDate: "",
    price: "",
    image: "",
  });

  const [error, setError] = useState({
    originAirport: "",
    destinationAirport: "",
    startDate: "",
    endDate: "",
    price: "",
    image: "",
  });

  const validate = (name, value) => {
    switch (name) {
      case "originAirport":
        if (!value || value.trim() === "") {
          return "Origin Airport is required";
        } else {
          return "";
        }

      case "destinationAirport":
        if (!value || value.trim() === "") {
          return "Destination Airport is required";
        } else {
          return "";
        }
      // case "startDate":
      //   if (!value || value.trim() === "") {
      //     return "StartDate is required";
      //   } else {
      //     return "";
      //   }
      // case "endDate":
      //   if (!value || value.trim() === "") {
      //     return "EndDate is required";
      //   } else {
      //     return "";
      //   }
      case "price":
        if (!value || value.trim() === "") {
          return "Price is required";
        } else {
          return "";
        }
      case "image":
        if (!value || value.trim() === "") {
          return "Image is required";
        } else {
          return "";
        }

      default: {
        return "";
      }
    }
  };
  const handleOnchange = (event) => {
    const { name, value } = event.target;
    // console.log(event.target.value);
    setError({ ...error, [name]: validate(name, value) });
    setInputValue({ ...inputvalue, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationError = {};
    console.log(inputvalue);
    Object.keys(inputvalue).forEach((name) => {
      const error = validate(name, inputvalue[name]);
      if (error && error.length > 0) {
        validationError[name] = error;
      }
    });
    if (Object.keys(validationError).length > 0) {
      setError({ ...validationError });
    }
  };

  return (
    <Box sx={{ p: "10%", px: "25%" }}>
      <Grid
        textAlign="center"
        sx={{ p: "2%", border: "1px solid grey" }}
        spacing={2}
      >
        <Grid>
          <Typography variant="h4">Flight Deals Form</Typography>
        </Grid>{" "}
        <br />
      
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              type="text"
              onChange={handleOnchange}
              value={inputvalue.originAirport.value}
              placeholder="Origin Airport"
              name="originAirport"
            />
            <Typography variant="span" sx={{ color: "red" }}>{error.originAirport}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              type="text"
              onChange={handleOnchange}
              value={inputvalue.destinationAirport.value}
              placeholder="Destination Airport"
              name="destinationAirport"
            />
            <Typography variant="span" sx={{ color: "red" }}>{error.destinationAirport}</Typography>
          </Grid>
        </Grid><br/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth required>
                <DatePicker
                  value={inputvalue.startDate.value}
                  name="startDate"
                  format="MMM DD"
                  renderInput={(props) => <TextField {...props} />}
                />{" "}
              </FormControl>
            </LocalizationProvider>
            <Typography variant="span" style={{ color: "red" }}>{error.startDate}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth required>
                <DatePicker
                  value={inputvalue.endDate.value}
                  name="endDate"
                  format="MMM DD"
                  renderInput={(props) => <TextField {...props} />}
                />{" "}
              </FormControl>
            </LocalizationProvider>
            <Typography variant="span" style={{ color: "red" }}>{error.endDate}</Typography>
          </Grid>
        </Grid><br/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              type="text"
              onChange={handleOnchange}
              value={inputvalue.price.value}
              placeholder="Price"
              name="price"
            />
            <Typography variant="span" style={{ color: "red" }}>{error.price}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              type="file"
              onChange={handleOnchange}
              value={inputvalue.image.value}
              name="image"
            />
            <Typography variant="span" style={{ color: "red" }}>{error.image}</Typography>
          </Grid>
        </Grid><br/>
        <Button variant="contained" type="submit" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default FlighDealsForm;
