import React from "react";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import { Box, Grid, Typography } from "@mui/material";

import Subscribe from "../../components/Subscribe/Subscribe";
import FooterComp from "../../components/FooterComp/FooterComp";
const BookingRule = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "600" }}>
              Rules and Regulation
            </Typography>
            <br />

            <Typography variant="p">
              1. Double-check your trip's details to make sure the times and
              dates are correct.
              <br /> <br />
              2. Accurate Passenger Names: Passenger names on tickets must
              precisely match those on government issued picture IDs. <br /> <br />
              3. No Name Changes: After tickets have been issued, name changes
              are not permitted and neither are transfers or refunds. <br /> <br />
              4. Connect-O-Fly's Liability: Connect-O-Fly is not responsible for
              errors in your booking that occur after it has been issued. <br /> <br />
              5. Total Fares and Fees: Total fares cover all taxes and fees,
              except extra airline charges for things like luggage and seat
              selection. <br /> <br />
              6. Billing details: Your credit card may be charged in a number of
              transactions for the ARC, Connect-O-Fly, or Agent cost. <br /> <br />
              7. Changes to fares are permitted in accordance with airline
              policies, although there may be change charges and pricing
              differences.
              <br /> <br />
              8. Fares are only guaranteed after the ticket has been issued.
              Fares are not guaranteed until the ticket has been issued. After
              receiving payment, an e-ticket will be provided within 3 to 24
              hours. <br /> <br />
              9. Visa and Travel Documents: All travellers are
              required to secure the appropriate visas and travel
              documents for the nations they will be visiting or
              passing through.
               <br /> <br />
              10. Reliable Travel Information: For up-to-date information on
              overseas travel, visit www.travel.state.gov and the relevant
              consulate or embassy.
              <br /> <br />
              11. Separate Tickets and Airline Terms: If your itinerary uses
              more than one airline, abide by their policies on changes,
              penalties, and other requirements. <br /> <br />
              12. Confirm Flights Directly: Due to potential last minute
              schedule changes, flights should be verified directly with
              airlines.
              <br /> <br />
              13. Seat and Special Requests: Airlines are notified of requests
              for seats, meals, and other services, but these requests are not
              always fulfilled. To receive a prompt confirmation, contact the
              airline.
              <br /> <br />
              14. Declined Credit Card Transactions: If your credit card is
              declined, we'll let you know within 24 hours. In this instance,
              fare and information are not guaranteed. <br /> <br />
              15. Connect-O-Fly will not offer compensation for changes to
              flight schedules. Confirm flights with airlines, then confirm
              again no later than 48 hours before travel. <br /> <br />
              16. Date Change and Cancellation: Please contact us 72 hours prior
              to your departure if you need to make adjustments or cancel.
              Changes are subject to penalties and limitations for airline
              fares. <br /> <br />
              17. Requirements for Check-In: Arrive two hours before to
              departure for Domestic flights: Within the US or India. Arrive at
              least 45 minutes before to departure time. International flights:
              Be at the airport three hours prior to take-off. At least 60
              minutes before the scheduled departure, check in. <br /> <br />
              18. No Refunds After Departure: Once the trip has started, tickets
              are no longer refundable. <br /> <br />
              19. No-Show Policy: If you fail to show up before departure, your
              ticket is worthless.
            </Typography>
          </Grid>
          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <Subscribe />
      <FooterComp />
    </Box>
  );
};

export default BookingRule;
