/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  Grid,
  CssBaseline,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../pages/NavigationManager/Navbar";
import DrawerComponent from "../Drawer/DrawerComponent";

const Header = () => {
  const theme = createTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar sx={{ background: "white" }} position="static">
          <Toolbar>
            {isMatch ? (
              <Grid container>
                <Grid xs={11} sm={11}>
                  <img
                    src={logo}
                    alt="Connect O Fly"
                    style={{ marginLeft: "10px", maxWidth: "140px" }}
                  />
                </Grid>
                <Grid xs={1} sm={1}>
                  <DrawerComponent />
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item md={3} lg={4}>
                  <a href="/">
                    <img
                      src={logo}
                      alt="Connect O Fly"
                      style={{ marginLeft: "10px", maxWidth: "140px" }}
                    />
                  </a>
                </Grid>
                <Grid item md={6} lg={5}>
                  <Navbar />
                </Grid>
                <Grid item md={3} lg={3}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#6e7a83",
                    }}
                  >
                    Call us 24/7{" "}
                    <Link
                      sx={{
                        color: "#ff8000",
                        textDecoration: "none",
                        fontSize: "20px",
                      }}
                    >
                      1-234-456-7890
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Outlet />
    </Box>
  );
};

export default Header;
