import { React } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import FooterComp from "../../components/FooterComp/FooterComp";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
const OurCulture = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "600",
              }}
            >
              Our Culture:
            </Typography>
            <br />

            <Typography variant="p">
              Our Special Workplace at Connect-O-Fly The Connect-O-Fly Team{" "}
              <br />
              <br />
              When you travel with Connect-O-Fly, you're not simply starting a
              job you're starting an unforgettable experience that you'll never
              forget. You can learn something new about your own potential,
              aspirations, and objectives every day. Our first objective is to
              develop a work atmosphere that inspires and challenges our staff.
              <br />
              <br />
              At Connect-O-Fly, we understand that an organization's culture may
              be a strong motivator for its people to succeed. High achievers
              and outstanding talent are drawn to and inspired by exceptional
              leaders.
              <br />
              <br />
              Our culture is a dynamic entity! The values of unity, fun,
              cooperation, and friendship are the foundation of our culture.
              <br />
              <br />
              We have the same great regard for our team members as we do for
              our clients. We firmly believe that the employees that make up our
              business are what make customers choose us over our rivals.
              <br />
              <br /> Our tenet is to invest in the development of our staff. For
              the purpose of making sure we stay ahead of the competition, we
              constantly organize expert training sessions and seminars.
              <br />
              <br />
              Our culture extends beyond conveniences like catered lunches and
              assigned parking. We make an attempt to fully comprehend our team.
              We are better able to connect personally when we have regular
              meetings and one-on-one interactions. At Connect-O-Fly, we value
              the opinions of every team member because we understand how
              important their input is to the success of both our company and
              our culture.
              <br />
              <br />
              Stress-relieving team-building exercises and regular outings
              foster a sense of togetherness among our personnel. Our
              accommodating work policies allow them to maintain a balance
              between their personal and professional lives because we are aware
              that a happy employee performs better overall.
              <br />
              <br />We are committed to giving our team members a sense of
              security and a clear route to a successful career that is replete
              with countless opportunities for learning and progress. <br />
              <br />
              Our work culture revolves around our invaluable team members - the
              true stars of Connect-O-Fly!
            </Typography>
          </Grid>

          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <FooterComp />
    </Box>
  );
};

export default OurCulture;
