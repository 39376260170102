import React from "react";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import social from "../../assets/images/social.png";
import live from "../../assets/images/live.png";
import safe from "../../assets/images/safe.png";
import price from "../../assets/images/price.png";

const BookWithUs = () => {
  return (
    <Wrapper>
      <Box
        component="footer"
        sx={{
          backgroundColor: "white",
          p: 12,
        }}
      >
        <Container maxWidth="lg" style={{ textAlign: "center" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "24px",
              lineHeight: "28px",
              paddingBottom: "55px",
            }}
          >
            Why Book With Us?
          </h1>

          <Grid container spacing={8}>
            <Grid item xs={12} sm={3}>
              <div className="wrapper-image">
                <img
                  src={social}
                  height={"35px"}
                  className="imge-box"
                  alt="img"
                />
              </div>

              <p style={{ fontSize: "18px", fontWeight: "bolder" }}>
                Special Negotiated Deals
              </p>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="wrapper-image">
                <img
                  src={live}
                  height={"40px"}
                  className="imge-box"
                  alt="img"
                />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                24/7 Live Support
              </p>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="wrapper-image">
                <img
                  src={safe}
                  height={"40px"}
                  width={"50px"}
                  className="imge-box"
                  alt="img"
                />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                100% Safe Shopping
              </p>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="wrapper-image">
                <img
                  src={price}
                  height={"40px"}
                  width={"50px"}
                  className="imge-box"
                  margin="50px"
                  alt="img"
                />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                Best Price Guarantee
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .rating {
    background-color: #00b67a;
    display: inline-block;
    width: 30px;
    height: 33px;
    margin: 2px;
  }

  .rating-icon {
    color: white;
    font-size: 25px;
    margin: 5px;
  }
  .rating-icon-1 {
    color: #00b67a;
    font-size: 40px;
  }
  .icons {
    background-color: #00b67a;
    display: inline-block;
    width: 25px;
    height: 23px;
    margin: 3px;
    margin-bottom: 15px;
  }

  .icon {
    color: white;
    font-size: 18px;
    margin: 5px;
  }

  .wrapper-image {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(16, 35, 49, 0.15);
    width: 90px !important;
    height: 90px !important;
    background-color: #b1d5ef;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    margin: 27px 18px;
  }
`;
export default BookWithUs;
