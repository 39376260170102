import { Box, Typography } from "@mui/material";
import React from "react";

const GroupTravel = () => {
  return (
    <Box
      sx={{
        lineHeight: "25px",
        p: "5%",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
        }}
      >
        Experience Seamless Group Travel with Us
      </Typography>
      <br />

      <Typography variant="p">
        Are you planning to embark on a journey with a group of 5 or more
        travellers?
        <br />
        <br />
        Look no further; our group travel programme was created with your needs
        in mind. Because we are familiar with the complexities of group travel,
        we have developed a programme that provides exclusive discounts,
        discounted airfares, and unmatched flexibility to make your trip not
        only affordable but also pleasant and fun.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "600" }}>
          Benefits of our group travel programme include:
        </Typography>
        <br />
        1. Discounted Airfare: Our programme provides special group rates to
        more than 500 locations, enabling you to experience significant
        discounts.
        <br />
        <br />
        2. Bundled Savings: Increase your savings by bundling your travel
        arrangements, including your hotel stay, rental vehicle, and flights.
        <br />
        <br />
        3. Larger Group Discounts: We feel that no organisation is too little or
        too large, thus we provide additional savings for larger groups.
        <br />
        <br />
        4. No Extra Fees: We are dedicated to openness, therefore there won't be
        any more expenses on top of your group travel plans.
        <br />
        <br />
        5. Flexible Payments: We offer flexible payment alternatives to meet the
        needs of your group's budget.
        <br />
        <br />
        6. Name Changes: We provide name change flexibility for your group
        members because we recognise that plans can change.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "600" }}>
          Our group travel programme is suitable for the following people:
        </Typography>
        <br />
        Our group travel programme serves a wide range of tourists, including:
        <br />
        <br />
        1. Enjoy the journey with family and friends to make lifelong memories.{" "}
        <br />
        <br />
        2. School groups: Make smooth travel arrangements to improve educational
        experiences. <br />
        <br />
        3. Religious organisations: Make spiritual journeys easier by providing
        stress-free travel arrangements. <br />
        <br />
        4. Corporate Groups: By participating in group vacation activities,
        strengthen team ties. <br />
        <br />
        5. Sports teams: Attend sporting events or compete together while on the
        road. <br />
        <br />
        6. Wedding parties: Travelling together will make the wedding
        festivities much more memorable. <br />
        <br />
        Our broad airline agreements allow us to offer substantially discounted
        tickets to meet your group's needs. We have experience effectively
        planning group travel for groups of 5 to 1700 people.
      </Typography>
    </Box>
  );
};

export default GroupTravel;
