import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCityTownDetailsURL } from "../../Services/apiConfig";

const initialState = {
  cityTownList: [],
  loading: false,
  error: null,
};
 

export const fetchCityTownList = createAsyncThunk(
  "fetchCityTownList",
  async (rejectWithValue) => {
    const response = await fetch(getCityTownDetailsURL());

    try {
      const data = await response.json();
      // console.log("data", data.results);
      return data.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cityTownListDetail = createSlice({
  name: "cityTownList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCityTownList.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchCityTownList.fulfilled, (state, action) => {
        state.cityTownList = action.payload;
        state.loading = false;
      })

      .addCase(fetchCityTownList.rejected, (state) => {
        state.cityTownList = [];
        state.loading = false;
       
      });
  },
});



export default cityTownListDetail.reducer;
