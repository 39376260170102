import { React } from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import FooterComp from "../../components/FooterComp/FooterComp";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
const ReferEarn = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "600",
              }}
            >
              Refer and Earn at Connect-O-Fly:
            </Typography>
            <br />
            <Typography variant="p">
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                {" "}
                Participation in Our campaign:
              </Typography>
              To be eligible for this campaign, you must mention Connect-O-Fly
              to a friend, coworker, or business contact. The person being
              referred must not be currently involved in discussions with us in
              order to qualify for this programme.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Fair Allocation:
              </Typography>
              We will follow a first-come, first served policy in cases where
              the same company is recommended by numerous people. If such a
              circumstance occurs, we will swiftly let you know if your
              recommendation has already been made. <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Booking Requirement:
              </Typography>
              The recommended party must continue to book with Connect-O-Fly in
              order for your referral to be eligible for this promotion.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Submission Requirements:{" "}
              </Typography>
              Only the form on the designated website may be used to submit
              referrals. Other submission methods won't be taken into
              consideration. Only referrals that meet the required booking
              criteria will be paid, according to the qualifying criteria. Be
              assured that we are dedicated to ensuring the process is fair.{" "}
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Eligibility Criteria:{" "}
              </Typography>
              In order for a referral to be considered eligible, the following
              criteria must be met:
              <br />
              • The booking must be initiated by a referred party who is not
              already known to Connect-O-Fly. <br />
              • The booking must be completed and payment must be made.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Payment Procedure:{" "}
              </Typography>
              Payments within the framework of the referral scheme will only be
              issued once a qualifying booking has been both made and paid for,
              adhering to the terms specified above.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Time Constraints:{" "}
              </Typography>
              Payments will not be processed for referrals made after the
              designated closing date of this scheme. Rest assured;
              Connect-O-Fly will promptly inform you when your referral
              successfully completes a qualifying booking.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Non-Qualifying Referrals:{" "}
              </Typography>
              Should your referral not fulfil the criteria for a qualifying
              booking within the promotion's stipulated terms, Connect-O-Fly
              will notify you accordingly. <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Unrestricted Referrals:
              </Typography>
              There are no restrictions on the number of referrals you can
              provide. Feel free to recommend as many individuals as you wish.{" "}
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Tax and Legal Responsibility:{" "}
              </Typography>
              It is your responsibility to address any tax or social security
              obligations applicable in your tax jurisdiction, if applicable.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Consent for Marketing:{" "}
              </Typography>
              By agreeing to the Terms and Conditions of this referral scheme,
              you affirm that you are authorized to share the contact
              information of the referred party with Connect-O-Fly for marketing
              purposes.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Data Privacy:
              </Typography>
              Any personal data collected for the purpose of this promotion will
              be processed in accordance with the provisions outlined in
              Connect-O Fly's Privacy Policy.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Booking Terms:{" "}
              </Typography>
              All bookings are subject to Connect-O Fly Travel's standard
              booking terms and conditions.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Right to Withdraw:{" "}
              </Typography>
              We retain the right to terminate the scheme and its associated
              benefits at any time and without prior notification.
            </Typography>
          </Grid>
          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <FooterComp />
    </Box>
  );
};

export default ReferEarn;
