import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { styled, useTheme } from "styled-components";
import FooterLastComp from "../FooterLastComp/FooterLastComp";
import logo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import linkdn from "../../assets/images/linkdn.png";
import instagram from "../../assets/images/instagram.png";

export default function Footer() {
  const column1 = [
    {
      id: 0,
      link: "Flights to Europe",
    },
    {
      id: 1,
      link: "Flights to South America",
    },
    {
      id: 2,
      link: "Flights to Paris",
    },
    {
      id: 3,
      link: "Flights to Italy",
    },
    {
      id: 4,
      link: "Flights to Puerto Rico",
    },
    {
      id: 5,
      link: "Flights to Ireland",
    },
    {
      id: 6,
      link: "Flights to Bangkok",
    },
    {
      id: 7,
      link: "Flights to Cancun",
    },
  ];

  const column2 = [
    {
      id: 0,
      link: "Qatar Airways",
    },
    {
      id: 1,
      link: "British Airways",
    },
    {
      id: 2,
      link: "France Airways",
    },
    {
      id: 3,
      link: "Etihad Airways",
    },
    {
      id: 4,
      link: "Lufthansa",
    },
    {
      id: 5,
      link: "Brussels Airlines",
    },
    {
      id: 6,
      link: "Saudi Arabian Airlines",
    },
    {
      id: 7,
      link: "Air Canada",
    },
  ];

  const column3 = [
    {
      id: 0,
      link: "Las Vegas",
    },
    {
      id: 1,
      link: "New York",
    },
    {
      id: 2,
      link: "Dallas",
    },
    {
      id: 3,
      link: "Miami",
    },
    {
      id: 4,
      link: "Houston",
    },
    {
      id: 5,
      link: "Chicago",
    },
    {
      id: 6,
      link: "Seattle",
    },
    {
      id: 7,
      link: "San Francisco",
    },
  ];
  const column4 = [
    {
      id: 0,
      link: "About Us",
      route: "/about-us",
    },
    {
      id: 1,
      link: "Affiliates",
      route: "/affiliates",
    },
    {
      id: 2,
      link: "Terms & Conditions**",
      route: "/terms-and-condition",
    },
    {
      id: 3,
      link: "Our Culture",
      route: "/out-culture",
    },
    {
      id: 4,
      link: "Refer & Earn",
      route: "/refer-and-earn",
    },
    {
      id: 5,
      link: "Contact Us",
      route: "/contact-us",
    },
    {
      id: 6,
      link: "Privacy Policy",
      route: "/privacy-policy",
    },
    {
      id: 7,
      link: "Booking Rules",
      route: "/booking-rules",
    },
    {
      id: 8,
      link: "Updates",
      route: "/updates",
    },
  ];

  return (
    <>
      <Wrapper>
        <Box
          sx={{
            background: "#cfcfcf",
            p: { lg: 12, md: 12, sm: 12, xs: 12 },
          }}
        >
          <Grid container spacing={3}>
            <Grid item sx={12} sm={6} md={3}>
              <Box style={{ marginBottom: "15px" }}>
                <img
                  src={logo}
                  alt="Connect-O-Fly"
                  style={{ maxWidth: "160px" }}
                />
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#f97150",
                  }}
                >
                  Call us :
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  1-234-456-7890
                </Typography>
              </Box>

              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#f97150",
                  }}
                >
                  Email :
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  info@connectofly.com
                </Typography>

                <Typography
                  style={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#f97150",
                  }}
                >
                  Follow on Us
                </Typography>

                <Link href="#" color="inherit">
                  <img src={instagram} alt="img" width="30px" />
                </Link>
                <Link href="#" color="inherit">
                  <img src={twitter} alt="img" width="30px" />
                </Link>
                <Link href="#" color="inherit">
                  <img src={linkdn} alt="img" width="30px" />
                </Link>
                <Link href="#" color="inherit">
                  <img src={facebook} alt="img" width="30px" />
                </Link>
              </Box>
            </Grid>

            <Grid item sx={12} sm={6} md={2}>
              <Box>
                <Typography
                  color="text.primary"
                  gutterBottom
                  style={{ fontSize: "18px" }}
                >
                  Flights By Region
                </Typography>
                {column1.map((box, index) => (
                  <Box className="box-top">
                    <Box key={index}>
                      <Link
                        href="#"
                        style={{ fontSize: "13px" }}
                        underline="hover"
                        className="hover-link"
                      >
                        {box.link}
                      </Link>
                      <br></br>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>

            <Grid item sx={12} sm={6} md={2}>
              <Typography
                color="text.primary"
                gutterBottom
                style={{ fontSize: "18px" }}
              >
                Popular Airlines
              </Typography>
              <Box>
                {column2.map((box2, index) => (
                  <Box className="box-top">
                    <Box key={index}>
                      <Link
                        href="#"
                        style={{ fontSize: "13px" }}
                        underline="hover"
                        className="hover-link"
                      >
                        {box2.link}
                      </Link>
                      <br></br>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>

            <Grid item sx={12} sm={6} md={3}>
              <Typography
                color="text.primary"
                gutterBottom
                style={{ fontSize: "18px" }}
              >
                Popular Cities in USA
              </Typography>
              <Box>
                {column3.map((box3, index) => (
                  <Box className="box-top">
                    <Box key={index}>
                      <Link
                        href="#"
                        style={{ fontSize: "13px" }}
                        underline="hover"
                        className="hover-link"
                      >
                        {box3.link}
                      </Link>
                      <br></br>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>

            <Grid item sx={12} sm={6} md={2}>
              <Typography
                color="text.primary"
                gutterBottom
                style={{ fontSize: "18px" }}
              >
                Supports
              </Typography>
              <Box>
                {column4.map((box4, index) => (
                  <Box className="box-top">
                    <Box key={index}>
                      <Link
                        href={box4.route}
                        style={{ fontSize: "13px" }}
                        underline="hover"
                        className="hover-link"
                      >
                        {box4.link}
                      </Link>
                      <br></br>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <hr
            style={{
              marginTop: "50px",
              marginBottom: "20px",
              color: "black",
            }}
          />

          <FooterLastComp/>
          <Box
            style={{
              marginTop: "20px",
              paddingLeft: "5%",
              paddingRight: "5%",
              textAlign: "center",
            }}
          >
            <Typography style={{ fontSize: "14px" }}>
              2020-{new Date().getFullYear()} &copy;{" "}
              <Link to="#" underline="none" style={{ fontSize: "14px" }}>
                Connectofly (connectofly.com). All Rights Reserved.
              </Link>
            </Typography>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  .hover-link:hover {
    color: #f97150;
  }
`;
