import {
  Box
} from "@mui/material";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import FooterComp from "../../components/FooterComp/FooterComp";
import TravelRestrictions from "../../components/TravelRestrictions/TravelRestrictions";
import CustomerSlider from "../../components/CustomerSlider/CustomerSlider";
import Subscribe from "../../components/Subscribe/Subscribe";
import BookWithUs from "../../components/BookWithUs/BookWithUs";
import FlightSearch from "../../components/FlightSearch/FlightSearch";
import LowestFlightDeals from "../../components/LowestFlightDeals/LowestFlightDeals";

const Flights = () => {


  return (
    <Box>
      <FlightSearch />
      <FooterComp />
      <TravelRestrictions />
      <LowestFlightDeals />
      <CustomerSlider />
      <Subscribe />
      <BookWithUs />
    </Box>
  );
};

export default Flights;
