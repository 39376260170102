import React from "react";
import { Box, Container, Grid, Typography, Hidden } from "@mui/material";
import star from "../../assets/images/star.png";
import trustpilot from "../../assets/images/trustpilot.png";

const FooterComp = () => {
  return (
    <Grid
      container
      spacing={1}
      sx={{ px: "15%", py: "1%" }}
      alignItems="center"
    >
      <Hidden lgDown>
        <Grid item lg={3}>
          <Typography variant="p">Our customers say</Typography>
        </Grid>
      </Hidden>

    
        <Grid item xs={6} sm={6} md={6} lg={2} sx={{ textAlign: "right" }}>
          <span
            style={{
              fontSize: "25px",
              fontWeight: "400",
              color: "#191919",
            }}
          >
            Excellent
          </span>
        </Grid>
   
      <Grid item xs={5} sm={4} md={6} lg={3}>
        <Box>
          <img
            src={star}
            alt="img"
            style={{ width: "20px", marginLeft: "2%", height: "22px" }}
          />
          <img
            src={star}
            alt="img"
            style={{ width: "20px", marginLeft: "2%", height: "22px" }}
          />
          <img
            src={star}
            alt="img"
            style={{ width: "20px", marginLeft: "2%", height: "22px" }}
          />
          <img
            src={star}
            alt="img"
            style={{ width: "20px", marginLeft: "2%", height: "22px" }}
          />
          <img
            src={star}
            alt="img"
            style={{ width: "20px", marginLeft: "2%", height: "22px" }}
          />
        </Box>
      </Grid>
      <Hidden lgDown>
        <Grid item lg={4}>
          <Typography variant="span">
            4.9 out of 5 based on 7,782 reviews
          </Typography>
        </Grid>
      </Hidden>      
    </Grid>
  );
};

export default FooterComp;
