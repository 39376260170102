import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import BookingFlights from "../../components/BookingFlights/BookingFlights";
import CheapFlightFilter from "../CheapFlightFilter/CheapFlightFilter";
import data from "../../JsonFiles/OneWayTrip/OneWayTrip.json";
import FlightSearch from "../FlightSearch/FlightSearch";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFlightSearchOneWay, getCreateFlightSearchOneWayData } from "../../Redux/Reducer/flightBookingTicketReducer";
import { createFlightSearchRoundWay } from "../../Redux/Reducer/flightSearchRoundWayReducer";
import CheapFlightComp from "../CheapFlightComp/CheapFlightComp";

const CheapFlightDeal = () => {
  const dispatch = useDispatch();
  const cheapFlightDeal = "cheap-flight-deal";
  const [filteredData, setFilteredData] = useState(data.data.legs);

  const handleFilter = (filteredData) => {
    setFilteredData(filteredData);
  };

  const [searchParams] = useSearchParams();

  const departure_airport_code = searchParams.get("departure_airport_code");
  const arrival_airport_code = searchParams.get("arrival_airport_code");
  const departure_date = searchParams.get("departure_date");
  const number_of_adults = searchParams.get("number_of_adults");
  const number_of_childrens = searchParams.get("number_of_childrens");
  const number_of_infants = searchParams.get("number_of_infants");
  const cabin_class = searchParams.get("cabin_class");
  const currency = searchParams.get("currency");
  const arrival_date = searchParams.get("arrival_date");
  const tripType = searchParams.get("tripType");

  const oneWayFormValues = {
    departure_airport_code: departure_airport_code,
    arrival_airport_code: arrival_airport_code,
    departure_date: departure_date,
    number_of_adults: number_of_adults,
    number_of_childrens: number_of_childrens,
    number_of_infants: number_of_infants,
    cabin_class: cabin_class,
    currency: currency,
  };

  console.log("oneWayFormValues", oneWayFormValues);

  const roundTripFormValues = {
    departure_airport_code: departure_airport_code,
    arrival_airport_code: arrival_airport_code,
    departure_date: departure_date,
    number_of_adults: number_of_adults,
    number_of_childrens: number_of_childrens,
    number_of_infants: number_of_infants,
    cabin_class: cabin_class,
    arrival_date: arrival_date,
    currency: currency,
  };

  /**
   * One-Way Show data page load
   */

  const FlightSearchOneWayData = useSelector(getCreateFlightSearchOneWayData);

  const airlineData = FlightSearchOneWayData?.data?.data;

  const airlineName = airlineData?.airlines[0].name;

  useEffect(() => {
    if (tripType === "ONE_WAY") {
      dispatch(createFlightSearchOneWay(oneWayFormValues));
    }
  }, []);

  /**
   * Round-Way Show data page load
   */
  useEffect(() => {
    if (tripType === "ROUND_TRIP") {
      dispatch(createFlightSearchRoundWay(roundTripFormValues));
    }
  }, []);

  return (
    <>
      <FlightSearch
        oneWayFormValues={oneWayFormValues}
        roundTripFormValues={roundTripFormValues}
        cheapFlightDeal={tripType}
      />
      <Box
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 4,
          background: "#eff0f6",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {" "}
            <CheapFlightFilter onFilter={handleFilter} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Grid container style={{ backgroundColor: "white" }}>
                <CheapFlightComp airlineData={airlineData} />
              </Grid>
            </Box>
            <BookingFlights
              allData={data.data.legs}
              filteredData={filteredData}
              airlineData={airlineData}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CheapFlightDeal;
