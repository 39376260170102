import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosObj from "../../Services/Api";
import { getCreateFlightSearchOneWayURL } from "../../Services/apiConfig";                         

/**
 * created a initial state
 */ 
const initialState = {
  flightSearchOneWay: [],
  loading: false,
  error: null,
};

/**
 * createFlightSearchOneWay function
 */
export const createFlightSearchOneWay = createAsyncThunk(
  "/flightSearchOneWay/create",
  async (parameters) => {
    console.log("parameters", parameters);
    try {
      const response = await axiosObj.post(getCreateFlightSearchOneWayURL(), parameters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
export const flightSearchOneWaySlice = createSlice({
  name: "flightSearchOneWays",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFlightSearchOneWay.pending, (state) => {
        state.loading = true;
      })

      .addCase(createFlightSearchOneWay.fulfilled, (state, action) => {
        state.flightSearchOneWay = action.payload;
        state.loading = false;
      })

      .addCase(createFlightSearchOneWay.rejected, (state) => {
        state.flightSearchOneWay = [];
        state.loading = false;
      });
  },
});

export const getCreateFlightSearchOneWayData = (state) =>
  state.flightSearchOneWays.flightSearchOneWay;
export const getLoadingState = (state) => state.flightSearchOneWays.loading;

/**
 * exported the reducer
 */
export default flightSearchOneWaySlice.reducer;
