import React from 'react';
function ArrivalAirportInfo({ data, arrivalAirportCode }) {

  const arrivalAirport = data.airports.find(
    (airport) => (airport.code === arrivalAirportCode)
  );

  return (
    <div>
      <p>{arrivalAirport ? arrivalAirport.name : 'Unknown'} ({arrivalAirportCode})</p>
    </div>
  );
}

export default ArrivalAirportInfo;