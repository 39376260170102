import React from 'react';
function DepartureAirportInfo({ data, departureAirportCode }) {

  const departureAirport = data.airports.find(
    (airport) => (airport.code === departureAirportCode)
  );

  return (
    <div>
      <p>{departureAirport ? departureAirport.name : 'Unknown'} ({departureAirportCode})</p>
    </div>
  );
}

export default DepartureAirportInfo;