import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showUser } from "../../Redux/Reducer/cutstomerSliderReducer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import { Hidden } from "@mui/material";
import Star from "../Helper/Star";
import data from "../../JsonFiles/CustomSlider/customSlider.json";
const CustomerSlider = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  const { customerList } = useSelector((state) => state.app1);
  // console.log("customerlist" , customerList)
  useEffect(() => {
    dispatch(showUser());
  }, [dispatch]);

  const formatTimeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (days === 1) {
      return "1 day ago";
    } else if (days < 7) {
      return `${days} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  };
  return (
    <>
      <Wrapper>
        <Box
          sx={{
            p: 8,
          }}
        >
          <Container>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "24px",
                lineHeight: "28px",
                marginTop: "10px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              We Care About Our Customers
            </Typography>
            <Box
              sx={{
                display: { lg: "flex", md: "block" },
              }}
            >
              <Hidden lgUp>
                <hr></hr>
              </Hidden>
              <Box className="grid-box">
                <Slider {...settings}>
                  {data.map((box, index) => (
                    <Box className="box-top">
                      <Box key={index}>
                        <Star rating={box.rating} />
                        <br />
                        <Typography
                          style={{
                            fontSize: "15px",
                            underline: "none",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {box.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "13px",
                            underline: "none",
                            color: "inherit",
                          }}
                        >
                          {box.description}
                        </Typography>{" "}
                        <Typography
                          variant="span"
                          style={{
                            fontSize: "13px",
                            underline: "none",
                            color: "inherit",
                            fontWeight: "600",
                          }}
                        >
                          {box.name}
                        </Typography>
                        <Typography
                          variant="span"
                          style={{
                            fontSize: "13px",
                            underline: "none",
                            color: "inherit",
                            marginLeft: "20px",
                          }}
                        >
                          {formatTimeAgo(box.date)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </Box>
            </Box>
          </Container>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .rating {
    font-size: 24px;
    color: gold; /* Change this to the color you want for the stars */
  }
  .rating-icon {
    margin-right: 5px;
  }
  .grid-box {
    width: 70%;
    margin: 0 auto;
    margin-top: 70px;
  }
  .box-top {
    cursor: pointer;
    overflow: hidden;
    height: auto;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .rating {
    background-color: #00b67a;
    display: inline-block;
    width: 25px;
    height: 22px;
    margin: 1px;
  }

  .rating-icon {
    color: white;
    font-size: 20px;
    margin: 3px;
  }
  .rating-icon-1 {
    color: #00b67a;
    font-size: 40px;
  }
  .slick-slider .slick-prev:before,
  .slick-slider .slick-next:before {
    color: #e1e3ef;
  }
`;

export default CustomerSlider;
