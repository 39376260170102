import { React } from "react";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

import FooterComp from "../../components/FooterComp/FooterComp";

import MoreInformation from "../../components/MoreInformation/MoreInformation";
const PrivacyPolicy = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "600" }}>
              Privacy Policy:
            </Typography>
            <br />
            <Typography variant="p">
              Data security and privacy protection are top priorities at
              Connect-O-Fly. Connect-O-Fly, Inc. ("Connect-O Fly"), the company
              behind www.Connect-O-Fly.com (the "Site"), is dedicated to
              protecting your Personal Information (information that can be used
              to identify you or from which you can be identified). We gather
              this information for a variety of objectives, such as but not
              limited to: addressing enquiries, handling applications, billing,
              communicating with you (also known as "you," the "traveller," or
              the "user"), receiving submissions for our referral programme, and
              publishing content on our travel blog. Your information is
              gathered when you order anything or sign up for our newsletter.{" "}
              <br />
              We are dedicated to adhering to relevant data protection laws.
              Through Intent Media, we provide personalized shopping experiences
              and advertising that utilizes online identifiers for visitor
              identification. To review Intent Media’s privacy policy, click
              here. To opt out, click here.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Use of Information:
              </Typography>
              The Personal Information collected is used exclusively to enhance,
              streamline, and deliver the services we provide. We do not share
              your Personal Information with external parties, except when
              necessary to process your requests or inquiries, during emergency
              situations, to enforce our policies, or when permitted by
              applicable law, such as responding to law enforcement or
              governmental authority requests, ongoing or proposed legal
              proceedings, or to safeguard our rights, property, and people. The
              information we gather from you can be utilized in the following
              ways: <br />
              1.Personalization: Your information enables us to cater to your
              individual needs and provide a personalized experience. <br />
              2.Website Enhancement: We continually strive to improve our
              website offerings based on the feedback and information we receive
              from you. <br />
              3.Customer Service Improvement: Your data empowers us to
              effectively respond to your customer service inquiries and support
              needs.
              <br />
              4.Transaction Processing: Your information, whether public or
              private, will not be shared, traded, transferred, or disclosed to
              other companies without your consent, except for delivering the
              requested purchased product or service. <br />
              5.Periodic Emails: The email address you provide for order
              processing may be used to send you updates related to your order,
              as well as occasional company news, product or service
              information, and related updates. <br />
              6.Marketing Connect-O-Fly Services: We may use your data to market
              our services to you, both as an existing or potential client,
              including providing promotional materials and offers for Connect-O
              Fly products and services. These communications may take various
              forms, including emails, postal mail, social media, and text
              messages. <br />
              To unsubscribe from future emails, detailed instructions are
              included at the bottom of each email.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Consent to Processing of Personal Information:{" "}
              </Typography>
              You expressly agree to this Privacy Policy and the transfer of
              your Personal Information across state and international borders
              as necessary for Connect-O-Fly to carry out its activities when
              you give Connect-O-Fly any Personal Information. This includes
              moving your personal information between several states,
              provinces, nations, and governmental jurisdictions as required to
              carry out Connect-O-Fly's business operations. Please be aware
              that your information may be transferred to computers and servers
              that are situated outside of your area, where the privacy
              regulations might not be as protective as those in your
              jurisdiction. If you choose to share information with us while
              residing outside of the United States, Connect-O-Fly will transfer
              and handle the information there. By agreeing to this Privacy
              Policy and providing your information, you signify your consent to
              this transfer.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Protecting Your Information:{" "}
              </Typography>
              We take stringent security measures to ensure the safety of your
              personal information when you make an order or input, submit, or
              access your personal details. <br />
              We utilize a secure server and employ Secure Socket Layer (SSL)
              technology to encrypt all sensitive/credit information during
              transmission. This encrypted information is stored in our Payment
              gateway providers' database and can only be accessed by authorized
              individuals with special access privileges. These authorized
              personnel are obligated to keep the information confidential.{" "}
              <br />
              Upon completing a transaction, your private information such as
              credit card details and financials will not be retained on our
              servers.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Collection of Non-Personally-Identifiable Information Online:{" "}
              </Typography>
              We may use various technologies to gather non Personal Information
              about your online visits. These technologies include: <br />
              1.Cookies: Cookies are small data pieces stored by your browser on
              your computer's hard drive. We may use cookies to collect
              information about your visits to our Site, including specific site
              areas you explore. Cookies may also help recognize if you've
              previously provided Personal Information, avoiding the need to
              request the same information again (e.g., username, password,
              contact details). While most browsers accept cookies by default,
              you can configure them to decline cookies. However, this might
              restrict your ability to fully utilize all Site features.
              <br />
              2.Clear GIFs: Pixel tags or clear gifs, along with similar
              tracking technologies, may be employed on our websites or in our
              communications to gather information about your website navigation
              or to determine whether you've visited a specific webpage or
              received a message. This data assists us in offering a better
              service tailored to your interests. <br />
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Do We Disclose Any Information to Outside Parties?{" "}
              </Typography>
              We prioritize the security of your personally identifiable
              information and do not sell, trade, or transfer it to external
              parties. However, we may engage trusted third parties to assist in
              website operations, business management, or customer service.
              These parties are required to maintain the confidentiality of your
              information. Additionally, we may share your information if we
              believe such sharing is necessary to comply with the law, enforce
              our site policies, or protect our rights, property, or safety, as
              well as that of others. Nonetheless, non-personally identifiable
              visitor information may be shared with other parties for
              marketing, advertising, or similar purposes.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Third-Party Website Links:
              </Typography>
              This privacy policy is solely applicable to the information
              collected from Connect-O-Fly own website and does not extend to
              data collected by others. It does not encompass the privacy or
              information collection practices of third-party websites linked to
              ours. Please note that when you navigate away from our site, you
              should review the privacy practices of those third-party websites
              before providing Personal Information. Connect-O-Fly holds no
              control or influence over third-party policies and cannot be held
              liable for any damage or actions stemming from linked external
              websites.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                California Online Privacy Protection Act Compliance:
              </Typography>
              We highly value your privacy and have taken necessary measures to
              comply with the California Online Privacy Protection Act.
              Consequently, we will not share your personal information with
              external parties without your consent. In accordance with this
              Act, all users of our site can make changes to their information
              at any time by logging into their account and editing their
              details.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Children's Online Privacy Protection Act Compliance:{" "}
              </Typography>
              We adhere to the requirements of COPPA (Children's Online Privacy
              Protection Act), meaning we do not collect any information from
              individuals under the age of 13. Our website, products, and
              services are intended for individuals aged 13 years or older.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Online Privacy Policy Only:{" "}
              </Typography>
              This online privacy policy exclusively pertains to information
              collected through our website, and does not encompass information
              collected offline.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Your Consent:
              </Typography>
              By utilizing our site, you signify your agreement to our web site
              privacy policy.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Changes to Privacy Policy:{" "}
              </Typography>
              Any alterations to the Privacy Policy will be posted on this page
              to ensure you are informed about the collection, usage, and
              disclosure of information. It is your responsibility to review
              Connect-O-Fly's Privacy Policy each time you provide Personal
              Information to Connect-O-Fly, as the policy might have changed
              since your last use of the Site.
            </Typography>
          </Grid>
          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <FooterComp />
    </Box>
  );
};

export default PrivacyPolicy;
