import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import FooterComp from "../../components/FooterComp/FooterComp";
const Affiliates = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box>
      <Grid container sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid
          lg={7}
          sx={{
            lineHeight: "25px",
            px: "3%",
            mb: "5%",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Affiliates:
          </Typography>
          <br />
          <Typography variant="p">
            As a cohesive group of international travel experts, Connect-O-Fly
            offers comprehensive travel solutions at affordable prices with
            outstanding service. We provide full services to both leisure and
            small business travelers, adapting experiences to satisfy a variety
            of demands, through our operating hubs in the USA and India. We have
            narrowed in on certain industries, like international ticketing and
            custom vacations.
            <br /> <br />
            1. Elevating Travel Together: As leading brokers for inexpensive
            travel from the USA to the Indian subcontinent, we have carved out a
            special place for ourselves in the world of international air
            ticketing. Our trademark is a combination of outstanding prices and
            exceptional customer service. Our dedication to your convenience is
            demonstrated by the availability of bookings via phone, online chat,
            or our user-friendly website, www.ConnectOFly.com, 24 hours a day, 7
            days a week.
            <br />
            <br />
            2. Unleash Earning Potential: We invite businesses like yours,
            including airlines, online travel agents, and digital marketers, to
            sign up for our Affiliate Programme, which opens the door to earning
            money through commissions on reservations for travel services like
            flights and vacation packages. The programme is completely
            transparent, free, and without any further fees. It takes little
            time and is easy to join.
            <br /> <br />
          </Typography>
          <Typography variant="span" sx={{ fontWeight: "600" ,fontSize:"20px"}}>
            Your Route to Gain:{" "}
          </Typography>
          <Typography variant="p">
            Partnering with us grants you access to a number of benefits:
            <br />
            <br />
            1. Exclusive Access: Get a first look at our tempting vacation
            packages and newest specials.
            <br />
            <br />
            2.Rich Commissions: Take advantage of lucrative monthly commissions
            on each reservation, which will greatly increase your revenue.
            <br />
            <br />
            3.Providing Rewards for Excellence Bookings with a high volume open
            the door to more rewards that honour your outstanding performance.
            <br />
            <br />
            4.Tracking in real-time: Keep up a constant connection to your
            account to easily track your progress and revenue.
          </Typography>
          <br /> <br />
          <Typography variant="p">
            Examine Collaboration: We urge you to complete our question form if
            you want to show showcase your goods or services on
            Connect-O-Fly.com. Give a brief description of your offerings
            together with the name and contact information for your business.
            Let's set out on a road towards prosperity and improvement for
            everyone.
            <br />
            <br />
            Ready to shape the future of travel together? Let's take flight with
            Connect-O-Fly.
          </Typography>
          <br />
          <br />
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Start Earning: Join Our Affiliate Program
          </Typography>
          <Link
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              color: "#1879c0",
              fontWeight: "600",
              fontSize: "20px",
              textDecoration: isHovered ? "underline" : "none",
            }}
          >
            info@ConnectOFly.com
          </Link>
        </Grid>
        <Grid lg={5} sx={{ px: "3%" }}>
          <MoreInformation />
        </Grid>
      </Grid>
      <FooterComp />
    </Box>
  );
};

export default Affiliates;
