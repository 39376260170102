import React from "react";
import arc from "../../assets/images/arc.svg";
import bbb from "../../assets/images/bbb.svg";
import norton from "../../assets/images/norton.png";
import visa from "../../assets/images/visa.svg";
import mastercard from "../../assets/images/mastercard.svg";
import discover from "../../assets/images/discover.svg";
import amex from "../../assets/images/amex.svg";
import { Box, Grid } from "@mui/material";

const FooterLastComp = () => {
  return (
    <Box sx={{ py: "2%", px: "20%" }}>
      <Grid container spacing={2}>
        <Grid item md={1.6} className="images">
          <img src={arc} alt="img" width="70%" />
        </Grid>
        <Grid item md={1.6} className="images">
          <img src={bbb} alt="img" width="70%" />
        </Grid>
        <Grid item md={1.8} className="images">
          <img src={norton} alt="img" width="80%" />
        </Grid>
        <Grid item md={1.6} className="images">
          <img src={visa} alt="img" width="60%" />
        </Grid>
        <Grid item md={1.6} className="images">
          <img src={mastercard} alt="img" width="60%" />
        </Grid>
        <Grid item md={1.6} className="images">
          <img src={discover} alt="img" width="60%" />
        </Grid>
        <Grid item md={1.6} className="images">
          <img src={amex} alt="img" width="60%" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterLastComp;
