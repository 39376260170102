import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAirportListURL } from "../../Services/apiConfig";
import axiosObj from "../../Services/Api";

/**
 * Define the initialstate
 */
const initialState = {
    airportData: [],
    isLoading: false,
    error: {
        message: "",
        code: "",
    },
};

export const fetchAirportList = createAsyncThunk(
    "/get-airports-list",
    async (search) => {
        try {
            const response = await axiosObj.get(getAirportListURL(search));
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit 
 */
const airportListSlice = createSlice({
    name: "airportLists",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAirportList.pending, (state) => {
                state.isLoading = true;
            })

            .addCase(fetchAirportList.fulfilled, (state, action) => {
                state.airportData = action.payload;
                state.isLoading = false;
            })

            .addCase(fetchAirportList.rejected, (state) => {
                state.isLoading = false;
                state.airportData = [];
            })
    },
});

export const getAirportList = (state) => state.airportLists.airportData;

/**
 * Export the reducer
 */
export default airportListSlice.reducer;
