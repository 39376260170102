import { Grid, Typography } from "@mui/material";
import React from "react";
import img from "../../assets/images/flight.svg";
import { styled } from "@mui/system";

const ResponsiveImage = styled("img")({
  width: "150px",
  "@media (min-width: 700px)": {
    width: "200px",
  },
  "@media (min-width: 1300px)": {
    width: "250px",
  },
});

const PageNotFound = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      sx={{ textAlign: "center", p: "10% 10%", backgroundColor: "#eff0f6" }}
    >
      <Grid item>
        <ResponsiveImage src={img} alt="img" />
      </Grid>
      <Grid item>
        <Typography variant="h5" sx={{ fontWeight: "600" }}>
          No flights found
        </Typography>
        <Typography variant="p">
          Sorry, no flights were found for these dates and route.
          <br />
          Try again with different dates or destination.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
