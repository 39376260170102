import {
  ListItemIcon,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import ExploreIcon from "@mui/icons-material/Explore";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const DRAWER_ITEMS = [
  {
    title: "Flights",
    icon: (iconColor) => <FlightIcon color={iconColor} />,
    route: "/",
  },
  {
    title: "Corporate Travel",
    icon: (iconColor) => <ExploreIcon color={iconColor} />,
    route: "/corporate-travel",
  },
  {
    title: "Group Travel",
    icon: (iconColor) => <ExploreIcon color={iconColor} />,
    route: "/group-travel",
  },
];

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const handleListItemClick = (index, drawerItem) => {
    navigate(drawerItem.route);
  };

  return (
    <Grid>
      <List
        sx={{
          display: "flex",
          color: "black",
        }}
      >
        {DRAWER_ITEMS.map((item, index) => (
          <ListItem
            key={item.title}
            onClick={() => handleListItemClick(index, item)}
            style={{ width: "auto" }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              {item.icon(
                location.pathname.split("/")[1] === item.route.split("/")[1]
                  ? theme.palette.primary.main
                  : theme.palette.primary.contrastText
              )}
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{ cursor: "pointer", color: "black" }}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default Navbar;
