import { React, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import letter from "../../assets/images/letters.webp";
import { isValidEmail } from "../../Utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeUser, getCreateSubscribeUser } from "../../Redux/Reducer/subscribeReducer";
import { useEffect } from "react";

const Subscribe = () => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: "",
  });

  const getSubscribeUser = useSelector(getCreateSubscribeUser);
  console.log("getSubscribeUser", getSubscribeUser);

  useEffect(()=>{
    dispatch(SubscribeUser());
  }, []);

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case "email":
        if (!value || value.trim() === "") {
          return "Email is required";
        } else if (!isValidEmail(value)) {
          return "Please enter valid email address";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  /**
   * handleSubmit values
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    let validationError = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationError[name] = error;
      }
    });
    if (Object.keys(validationError).length > 0) {
      setError({ ...validationError });
    }
    dispatch(SubscribeUser(inputValues))
  };

  /**
   * handle onchange values
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setError({ ...error, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  const colrs = getSubscribeUser?.data?.status === "failure" ? "red" : "green";

  return (
    <Box
      sx={{
        border: "1px solid #e1e3ef",
        backgroundColor: "#e1e3ef",
        p: { lg: "2% 20%", md: "2% 10%", sm: "6% 5%", xs: "6% 5%" },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#FFFFFF",
          p: { lg: "4% 2%", md: "2% 2%", sm: "4% 5%", xs: "4% 5%" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ p: { lg: "2% 5%", md: "2% 5%" } }}
          textAlign="center"
        >
          <Box>
            <img src={letter} alt="img" width="120px" />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            Subscribe to our newsletter and save!
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            Receive hot deals, secret promos and new articles
          </Typography>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TextField
                fullWidth
                required
                label="Email"
                type="email"
                placeholder="your.email@gmail.com"
                name="email"
                value={inputValues.email}
                onChange={handleOnChange}
                error={error.email ? true : false}
              />{" "}
              <FormHelperText sx={{ color: "error.main", ml: 0 }}>{error.email}</FormHelperText>
              <FormHelperText sx={{ color: colrs, ml: 0 }}>{getSubscribeUser?.data?.error?.message}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              {" "}
              <Button
                fullWidth
                required
                variant="contained"
                onClick={handleSubmit}
                sx={{ height: "50px", textTransform: "none" }}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subscribe;
