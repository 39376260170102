import React, { useState } from "react";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import ExploreIcon from "@mui/icons-material/Explore";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
const pages = [
  { name: "Flights",
   icon: (iconColor) => <FlightIcon color={iconColor} />,
    route: "/" },
  { name: "Corporate Travel",
    icon: (iconColor) => <ExploreIcon color={iconColor} />,
     route: "/corporate-travel" },
  { name: "Group Travel",
   icon: (iconColor) => <ExploreIcon color={iconColor} />, 
   route: "/group-travel" },
];
const DrawerComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleListItemClick = (index, pages) => {
    setOpenDrawer(false);
    navigate(pages.route);
  
  }
  const handleClick = () => {
    setOpenDrawer(false);
  }

  return (
    <React.Fragment>
      <Grid>
        <IconButton
          sx={{ color: "black" }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          style: {
            width: 230,
          },
        }}
      >
        <List>
        <Box ml="8%">
        <CloseIcon onClick={handleClick}/>
        </Box>
        
          {pages.map((item, index) => (
            <ListItemButton
              key={item.name}
              onClick={() => handleListItemClick(index, item)}
              
            >
             
                <ListItemIcon sx={{ minWidth: 32 }} >
                  {item.icon(
                    location.pathname.split("/")[1] === item.route.split("/")[1]
                      ? theme.palette.primary.main
                      : theme.palette.primary.contrastText
                  )}
                </ListItemIcon>
                 <ListItemText   primary={item.name}
                 sx={{ cursor: "pointer", color: "black" } }></ListItemText>
           
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
};
export default DrawerComponent;
