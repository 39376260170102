import { React, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { TextField, Button } from "@mui/material";
import { FormLabel } from "@mui/material";

const SubscriberAdminForm = () => {
  const [inputData, setInputData] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: "",
  });
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setError({ ...error, [name]: validate(name, value) });
    setInputData({ ...inputData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let validationError = {};
    Object.keys(inputData).forEach((name) => {
      const error = validate(name, inputData[name]);
      if (error && error.length > 0) {
        validationError[name] = error;
      }
    });
    if (Object.keys(validationError).length > 0) {
      setError({ ...validationError });
    }
    console.log("data" ,inputData);
  };
  const validate = (name, value) => {
    switch (name) {
      case "email":
        if (!value || value.trim() === "") {
          return "Please provide a correct email address";
        } else if (
          !/^[A-Z0-9. _%+-]+@[A-Z0-9. -]+\.[A-Z]{2,4}$/i.test(inputData.email)
        ) {
          return "Email is not valid format";
        } else {
          return "";
        }

      default: {
        return "";
      }
    }
  };
  return (
    <Wrapper>
      <Box
        sx={{
          backgroundColor: "white",
          p: 6,
          marginBottom: 20,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{ paddingLeft: "30px", width: "800px" }}
        >
          <Box className="refer-form">
            <Typography style={{ fontSize: "22px", marginBottom: "10px" }}>
              Get Subscribe Data
            </Typography>
            <form>
              <FormLabel>Email*</FormLabel>
              <TextField
                type="email"
                variant="outlined"
                color="secondary"
                label="your.email@gmail.com"
                fullWidth
                required
                name="email"
                onChange={handleOnChange}
                sx={{ mb: 2 , mt :2}}
              />
              <Typography style={{ color: "red" }}>{error.email}</Typography>
              <br></br>
              <Button
                variant="contained"
                type="submit"
                style={{ padding: 15 }}
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </form>
          </Box>
        </Grid>
      </Box>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  input {
    width: 45%;
    padding: 12px;
    margin-right: 20px;
  }
  Button {
    width: 150px;
    padding: 10px;
  }
  .rating {
    background-color: #00b67a;
    display: inline-block;
    width: 30px;
    height: 33px;
    margin: 2px;
  }

  .rating-icon {
    color: white;
    font-size: 25px;
    margin: 5px;
  }
  .rating-icon-1 {
    color: #00b67a;
    font-size: 40px;
  }
  .trust-text-box {
    margin-top: 15%;
  }

  .refer-form {
    padding: 40px;
    background-color: #e1e3ef;
    margin-top: 10%;
  }
`;
export default SubscriberAdminForm;
