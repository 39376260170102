import { Grid, Typography, Link, Box } from "@mui/material";
import React from "react";
import MoreInformation from "../../components/MoreInformation/MoreInformation";
import FooterComp from "../../components/FooterComp/FooterComp";
const Updates = () => {
  return (
    <Box>
      <Box sx={{ p: "5% 5% ", backgroundColor: "white" }}>
        <Grid container>
          <Grid
            lg={7}
            sx={{
              lineHeight: "25px",
              px: "3%",
              mb: "5%",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "600" }}>
              Updates
            </Typography>
            <br />
            <Typography variant="p">
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Important Coronavirus (COVID-19) Travel Information:
              </Typography>
              <br />
            
              U.S. Department of State Warning: Due to the ongoing Coronavirus
              epidemic, the U.S. Department of State on March 19, 2020, issued
              the highest Global Health Travel Warning, recommending all U.S.
              residents to stay away from overseas travel. It is strongly
              advised against travelling to places under this recommendation
              level in order to protect the safety of the public.
              <br />
              <br />{" "}
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Information on the Coronavirus Context:
              </Typography>
              <br />
            
              Hello, customers
              <br />
              <br />
              We recognise that you might be worried about your planned travel
              plans given the substantial impact the coronavirus outbreak has
              had on the travel sector. To give you the most recent information,
              we answer the most frequently asked questions below. As new data
              comes in, we will update this page as needed.
              <br/><br/><Typography variant="h6" sx={{ fontWeight: "600" }}>
                Key Topics Covered:
              </Typography>
              <br />
             
              1.Travel Advice and Entry Restrictions
              <br />
              <br />
              2.Assistance for Stranded Travelers
              <br />
              <br />
              3.Checking Flight Status
              <br />
              <br />
              4. Flight Cancellations
              <br />
              <br />
              5.Changing or Cancelling Bookings
              <br />
              <br />
              6.Contacting Customer Service
              <br />
              <br />
              We're committed to supporting you through this unusual crisis and
              will stop at nothing to make sure you can get where you're going
              or adjust your travel plans as needed. We are actively evaluating
              the impact as events unfold and taking appropriate action. Please
              consider assisting us in the following ways:
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Call Us:{" "}
              </Typography><br/>
              Please contact us by email at
              <Link color="#1879c0"> service@Connect-O-Fly.com</Link>. if you
              haven't already. Within 72 hours of your scheduled departure, our
              customer support staff will make every effort to get in touch with
              you.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Urgent Departures:{" "}
              </Typography><br/>
              If your trip will take place within the next four weeks, please
              get in touch. We can then give support to those who have upcoming
              departure dates priority.
              <br />
              <br />
              Please continue reading the information below for more specific
              details and responses to frequently asked questions concerning
              your reserved flights. We are committed to assisting you in
              navigating this circumstance, and we sincerely appreciate your
              patience and co-operation.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Travel Advice and Entry Restrictions:
              </Typography>
              <br />
            
              <Typography
                variant="p"
                sx={{ fontWeight: "600", color: "#1879c0" }}
              >
                Q1: What is the current travel advice?
              </Typography>
              <br />
        
              <Typography>
                {" "}
                <span style={{ color: "#1879c0", fontWeight: "600" }}>
                  A:
                </span>{" "}
                Travel advice varies depending on the country and region you are
                planning to visit. Please note that travel advice can change
                rapidly. For the most up-to-date information, we recommend
                checking the official website of your country's Foreign Affairs
                department.
              </Typography><br/>
              <Typography
                variant="p"
                sx={{ fontWeight: "600", color: "#1879c0" }}
              >
                Q2: What measures are being taken by national authorities?
              </Typography>
              <br />
             
              <Typography>
                {" "}
                <span style={{ color: "#1879c0", fontWeight: "600" }}>
                  A:
                </span>{" "}
                Many countries have implemented entry restrictions and
                health-related checks due to the COVID-19 pandemic. These
                measures can include health certificates, temperature
                screenings, and quarantine protocols upon arrival. To learn
                about the latest entry requirements for your destination, please
                visit the International Air Transport Association (IATA)
                website.
              </Typography>
              <br />
            
              <Typography
                variant="p"
                sx={{ fontWeight: "600", color: "#1879c0" }}
              >
                Q3: What if my flight is operational, but I can't enter my
                destination?
              </Typography>
              <br />
            
             
                {" "}
                <span style={{ color: "#1879c0", fontWeight: "600" }}>
                  A:
                </span>{" "}
                The ability to enter a country depends on various factors such
                as your departure country, travel history, and destination's
                entry policies. It's important to note that flight operations do
                not necessarily guarantee entry. It is your responsibility as a
                traveller to verify whether you can enter your destination.
                Check the official government website of your passport country
                and, if necessary, your destination country for entry
                requirements. Additionally, entry restrictions may be based on
                your travel history rather than just your nationality. For
                detailed entry requirements, visit the International Air
                Transport Association (IATA) website. We understand that this
                situation is dynamic and may raise concerns about your travel
                plans. Keep yourself informed through reliable sources, and feel
                free to reach out to us if you have specific questions or need
                assistance regarding your travel arrangements.
               <br/><br/> <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Stranded Abroad:
                </Typography>
                <br />
              
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q1: Are you stranded abroad?
                </Typography>{" "}<br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                If you find yourself stranded abroad due to sudden border
                closures and travel restrictions, we understand the challenges
                you may be facing. If you are unable to return home and require
                urgent repatriation, we recommend taking the following steps:
                <br />
                <br />
                1.Contact Your Embassy or Foreign Affairs: Reach out to the
                Embassy or Foreign Affairs office of your home country. Register
                with them and provide your information. This will help them
                assist you in case repatriation flights are organized.
                <br />
                <br />
                2. Stay Informed: Stay updated with the latest travel advisories
                and information from both your home country and the country you
                are in. Follow any instructions provided by local authorities
                and your Embassy.
                <br />
                <br />
                3.Communication: Keep lines of communication open with your
                family, friends, and relevant authorities. Inform them of your
                situation and plans.
                <br />
                <br />
                4.Patience: Understand that the situation is fluid, and
                repatriation efforts may take time to organize. Be patient and
                cooperative with authorities.
                <br />
                <br />
                Remember, your safety and well-being are of utmost importance.
                It's recommended to rely on official sources for accurate
                information and follow guidelines provided by both your home
                country and the host country. If you need any assistance or have
                questions about your travel arrangements, feel free to contact
                us.
                <br/><br/><Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Check Flight Status:
                </Typography>
                <br />
                
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q1: How can I check the status of my flight?
                </Typography>{" "}<br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                Due to the rapidly changing situation, flight statuses are
                subject to frequent updates. For the most accurate and
                up-to-date information about your flight,we recommend visiting
                the official website of the airline you are traveling with.
                There, you can find the latest flight status and any relevant
                information, especially if your flight has been affected by
                COVID-19.
                <br />
                <br />
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q2: My flight status is still OK; can I still travel?
                </Typography>   <br />
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                While your flight status may appear unaffected, it's important
                to note that travel conditions can change rapidly. Always stay
                informed by checking the latest travel advisories, entry
                requirements, and any other guidelines issued by both your
                departure and destination countries. Access to a destination is
                not solely dependent on flight operation; entry restrictions and
                regulations vary. Ensure you have the latest information before
                proceeding with your travel plans.
                <br />
                <br />
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  My Flight Is Cancelled:
                </Typography>
                <br />
             
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q1: Has my flight been cancelled?
                </Typography><br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                Flight statuses are currently undergoing rapid changes. To
                obtain the most accurate and current information about your
                flight, we recommend visiting the official website of the
                airline with which you booked your ticket. This will provide you
                with up-to-date details regarding your flight, including any
                cancellations that may have occurred due to the impact of
                COVID-19.
                <br />
                <br />
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q2: Why can it take a long time to get my refund?
                </Typography>{" "}<br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                As an intermediary between you and the airline, we facilitate
                the process of issuing tickets. Your payment for the ticket(s)
                you booked is directly transferred to the airline upon ticket
                issuance. Therefore, if a refund is applicable, it is the
                airline that initiates the refund process. The ongoing COVID-19
                pandemic has led to significant disruptions in the airline
                industry, resulting in extended timelines for refund processing.
                <br />
                <br />
                Due to these circumstances, refund processes are taking longer
                than usual. While we understand your concern, we are dependent
                on the individual airlines to process these refunds, which can
                lead to delays. At this time, we are unable to provide an exact
                timeframe for when you will receive your refund. Many airlines
                have indicated that it may take 10-12 weeks to process all
                refund requests. Please refrain from contacting us regarding
                this matter, as we are working diligently to process
                cancellations without requiring additional information from you.
                We apologize for any inconvenience caused and appreciate your
                patience and understanding during this time.
                <br />
                <br />
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  I Want to Change or Cancel:
                </Typography>
                <br />
              
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q1: What if I no longer want to travel? How can I CHANGE my
                  trip?
                </Typography>{" "}<br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                Changing your travel plans might incur additional costs,
                depending on the airline's terms and conditions. We are
                committed to adhering to the policies set by the airlines. To
                initiate a change request, please email us at{" "}
                <Link color="#1879c0"> service@Connect-O-Fly.com</Link>
                . with your reservation number. We strive to address your request
                within 72 hours prior to your scheduled departure date.
                <br />
                <br />
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q2: My flight status is still OK, but what if I no longer want
                  to travel? How can I CANCEL my trip?
                </Typography><br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                If you decide to cancel your trip, the possibility of receiving
                a refund is contingent on the terms outlined in your flight
                ticket conditions and the cancellation policy established by the
                airline. As a company, we are dedicated to adhering to the
                airlines' terms and conditions. To initiate a cancellation
                request, please email us at
                <Link color="#1879c0"> service@Connect-O-Fly.com</Link>. with
                your reservation number. We aim to respond to your request
                within 72 hours before your intended departure date.
                <br />
                <br />
                How does the process work?
                <br />
                <br />
                • If applicable, we will request the refund amount on your
                behalf.
                <br />
                <br />
                • We will notify you about the status of your refund request via
                email.
                <br />
                <br />
                • Once the airline has processed the refund amount to us, we
                will transfer the refund to you. Please note that refund
                processing timelines may be longer due to the current
                circumstances caused by COVID-19. We appreciate your patience as
                we work to facilitate your change or cancellation requests.
                <br />
                <br />
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Contact & Service:
                </Typography>
                <br />
            
                Our dedicated customer service team is working tirelessly day
                and night to address the high volume of requests. While our
                primary focus is on assisting customers with imminent departure
                dates, the sheer volume of inquiries remains substantial.
                Additionally, adhering to global recommendations, our entire
                team is working remotely, which presents its own set of
                challenges.
                <br />
                <br />
                Given the exceptional circumstances we find ourselves in,
                providing the usual level of service may not be feasible at this
                time.
                <br />
                <br />
                <Typography
                  variant="p"
                  sx={{ fontWeight: "600", color: "#1879c0" }}
                >
                  Q1: When can I contact customer service?
                </Typography>{" "}<br/>
                <span style={{ color: "#1879c0", fontWeight: "600" }}>A:</span>{" "}
                To ensure that we can serve our customers effectively, we are
                prioritizing those with the most urgent departure dates.
                Customers in such situations will receive priority assistance.
                For inquiries related to changes or cancellations, kindly email
                us at <Link color="#1879c0">care@Connect-O-Fly.com</Link>. To
                facilitate quicker processing and minimize wait times, we kindly
                request that you consider calling us only if your trip is
                scheduled within the next 72 hours. This approach allows us to
                better support travellers with imminent travel plans. Please be
                aware that our entire team is putting in extra hours to
                accommodate the needs of all our customers during this
                challenging period.
            
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid lg={5} sx={{ px: "3%" }}>
            <MoreInformation />
          </Grid>
        </Grid>
      </Box>
      <FooterComp />
    </Box>
  );
};

export default Updates;
