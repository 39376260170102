import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Corporate = () => {
  return (
    <Box
      sx={{
        lineHeight: "25px",
        p: "5%",

        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
        }}
      >
        Corporate Travel Solutions
      </Typography>
      <br />

      <Typography variant="p">
        At Connect-O-Fly, we specialise in offering specialised solutions for
        business travellers' needs, making the process simple and affordable.
        Our staff of seasoned professionals, who bring unmatched industry
        expertise and the capacity to negotiate the most affordable tickets,
        reflects our dedication to excellence. We are here to use our years of
        experience negotiating the complexities of business travel to your
        advantage.
        <br />
        <br />
        We assign a committed travel manager with years of experience to each of
        our clients. These professionals make efficient use of our
        state-of-the-art technology to make booking travel hassle-free. Our
        dedication to effectiveness and efficiency makes sure that your company
        gets the best possible return on investment.
        <br />
        <br />{" "}
        <Typography variant="h6" sx={{ fontWeight: "600" }}>
          Our Corporate Travel Services' Key Qualities
        </Typography>
        <br />
        1. 24/7 Emergency Support: To assist with any travel related emergency,
        our specialised helpline and chat support are available 24/7.
        <br />
        <br />
        2. Quick Ticket Issuance and Email Delivery: We promise prompt ticket
        issuance and email delivery. <br />
        <br />
        3. Personalised Relationship Manager: Take advantage of the ease of a
        committed relationship manager who is aware of your particular travel
        needs. <br />
        <br />
        4. We provide flexible payment options that adhere to your company's
        financial requirements. <br />
        <br />
        5. Cost Management: We focus on keeping expenses under control while
        maximising the return on your vacation investment. <br />
        <br />
        6. Complete Reporting: Get in-depth reports that track travel expenses
        and identify patterns to help you make smarter decisions. <br />
        <br />
        Our business travel services have already benefited a number of renowned
        clients, and they frequently provide excellent reviews of their
        interactions with us. We urge you to learn more about the benefits
        connectofly.com consultants can offer your business if you haven't
        previously done so.
        <br />
        <br />
        Please contact us at <Link color="#1879c0">
          {" "}
          Hello@connectofly.com
        </Link>{" "}
        if you have any further inquiries or requests for information. Within 24
        hours, a dedicated travel specialist will contact you to discuss how we
        can customise our services to fulfil your specific corporate travel
        requirements.
      </Typography>
    </Box>
  );
};

export default Corporate;
