import React from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  Button,
  Grid,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncAlt } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import Travelers from "../../components/Travelers/Travelers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createFlightSearchOneWay } from "../../Redux/Reducer/flightBookingTicketReducer";
import {
  fetchAirportList,
  getAirportList,
} from "../../Redux/Reducer/originDestinationFlightListReducer";

const OneWay = (props) => {
  const { oneWayFormValues, cheapFlightDeal } = props;

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * fetch Flight data
   */
  const oriDesList = useSelector(getAirportList);
  const data = oriDesList?.data;

  useEffect(() => {
    dispatch(fetchAirportList(searchValue));
  }, [searchValue]);

  const jsonArrayObject = [];

  if (data && Array.isArray(data)) {
    for (const val of data) {
      const dynamicKey = val.code;
      jsonArrayObject.push({
        name: `${val.city} (${val.code}) ${val.country}`,
        code: dynamicKey,
      });
    }
  }

  /**
   * Form Fields
   */
  const [inputValue, setInputValue] = useState({
    originAirport: {
      code: "",
    },
    destinationAirport: {
      code: "",
    },
    selectedDate: new Date(),
    adultsCount: 1,
    childrenCount: 0,
    infantsCount: 0,
    selectedOption: "Economy",
    currency: "USD",
  });

  /**
   * Form Errors
   */
  const [validationErrors, setValidationErrors] = useState({
    originAirport: "",
    destinationAirport: "",
  });

  const [searchParams] = useSearchParams();

  const departure_airport_code = searchParams.get("originAirport");
  const arrival_airport_code = searchParams.get("destinationAirport");
  const departure_date = searchParams.get("departure_date");
  const number_of_adults = searchParams.get("number_of_adults");
  const number_of_childrens = searchParams.get("number_of_childrens");
  const number_of_infants = searchParams.get("number_of_infants");
  const cabin_class = searchParams.get("cabin_class");

  const parsedDate = new Date(departure_date);

  const formattedDate = parsedDate.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    if (cheapFlightDeal === "ONE_WAY") {
      const newInputValues = {
        originAirport: {
          code: departure_airport_code ? departure_airport_code : "",
        },
        destinationAirport: {
          code: arrival_airport_code ? arrival_airport_code : "",
        },
        selectedDate: new Date(),
        adultsCount: number_of_adults ? number_of_adults : 1,
        childrenCount: number_of_childrens ? number_of_childrens : 0,
        infantsCount: number_of_infants ? number_of_infants : 0,
        selectedOption: cabin_class ? cabin_class : "",
      };
      console.log("newInputValues", newInputValues);
      setInputValue(newInputValues);
    }
  }, [cheapFlightDeal, formattedDate]);

  /**
   * handle Switch event
   */
  const handleSwitchValues = () => {
    setInputValue((prevInputValue) => ({
      ...inputValue,
      originAirport: prevInputValue.destinationAirport,
      destinationAirport: prevInputValue.originAirport,
    }));
  };

  /**
   * datepicker formatDate function
   */
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  /**
   * onSubmit Function
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!inputValue.originAirport.code) {
      newErrors.originAirport = "Origin airport is required.";
    }
    if (!inputValue.destinationAirport.code) {
      newErrors.destinationAirport = "Destination airport is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setValidationErrors(newErrors);
    } else {
      let formattedDate = formatDate(inputValue.selectedDate);

      // const regex = /\(([^)]+)\)/; // Regular expression to match text inside parentheses
      // const originMatch = inputValue.originAirport.code.match(regex);

      // const originAirCode = originMatch[1];

      // const destinationMatch = inputValue.destinationAirport.code.match(regex);

      // const destinationAirCode = destinationMatch[1];

      // const usersData = {
      //   departure_airport_code: originAirCode,
      //   arrival_airport_code: destinationAirCode,
      //   departure_date: formattedDate,
      //   number_of_adults: inputValue.adultsCount,
      //   number_of_childrens: inputValue.childrenCount,
      //   number_of_infants: inputValue.infantsCount,
      //   cabin_class: inputValue.selectedOption,
      //   currency: inputValue.currency,
      // };

      const usersData = {
        departure_airport_code: "PNQ",
        arrival_airport_code: "BOM",
        departure_date: "2023-09-04",
        number_of_adults: 1,
        number_of_childrens: 0,
        number_of_infants: 0,
        cabin_class: "Economy",
        currency: "USD",
      };

      if (cheapFlightDeal === "ONE_WAY") {
        dispatch(createFlightSearchOneWay(createFlightSearchOneWay));
      }

      navigate(
        `/cheap-flight-deal?departure_airport_code=${usersData.departure_airport_code}&arrival_airport_code=${usersData.arrival_airport_code}&departure_date=${usersData.departure_date}&number_of_adults=${usersData.number_of_adults}&number_of_childrens=${usersData.number_of_childrens}&number_of_infants=${usersData.number_of_infants}&cabin_class=${usersData.cabin_class}&currency=${usersData.currency}&originAirport=${usersData.departure_airport_code}&destinationAirport=${usersData.arrival_airport_code}&tripType=ONE_WAY`
      );
    }
  };

  /**
   * onChange event
   */
  const handleInputChange = (field, newValue) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [field]: newValue,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
    /**
     * clear searched values
     */
    setSearchValue("");
  };

  const handleOnKeyUp = (event) => {
    setSearchValue(event.target.value);
  };

  const airportListDropdown = searchValue
    ? jsonArrayObject.map((n) => n.name)
    : [];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className="form-container">
        <Grid item xs={12} sm={12} md={12} lg={2.7}>
          <Autocomplete
            fullWidth
            required
            freeSolo
            disableClearable
            options={airportListDropdown}
            value={inputValue.originAirport.code}
            onKeyUp={handleOnKeyUp}
            onChange={(_, newValue) =>
              handleInputChange("originAirport", {
                ...inputValue.originAirport,
                code: newValue,
              })
            }
            renderInput={(params) => (
              <Box style={{ position: "relative", display: "flex" }}>
                <TextField
                  {...params}
                  label="Origin Airport"
                  variant="outlined"
                  error={validationErrors.originAirport ? true : false}
                />
                <IconButton
                  onClick={handleSwitchValues}
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: "0",
                    zIndex: 1,
                    transform: "translate(-25%, -25%)",
                  }}
                >
                  <SyncAlt />
                </IconButton>
              </Box>
            )}
          />
          <FormHelperText sx={{ color: "error.main", ml: 0 }}>
            {validationErrors.originAirport}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2.7}>
          <Autocomplete
            fullWidth
            required
            freeSolo
            disableClearable
            options={airportListDropdown}
            value={inputValue.destinationAirport.code}
            onChange={(_, newValue) =>
              handleInputChange("destinationAirport", {
                ...inputValue.originAirport,
                code: newValue,
              })
            }
            onKeyUp={handleOnKeyUp}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination Airport"
                variant="outlined"
                error={validationErrors.destinationAirport ? true : false}
              />
            )}
          />
          <FormHelperText sx={{ color: "error.main", ml: 0 }}>
            {validationErrors.destinationAirport}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={2.7}>
          {/* <DatePicker
            className="myDatePicker"
            selected={inputValue.selectedDate ? new Date(inputValue.selectedDate) : null}
            name="selectedDate"
            value={inputValue.selectedDate}
            onChange={(date) => handleInputChange("selectedDate", date)}
            dateFormat="EEE, MMM d"
            minDate={new Date()}
            showYearDropdownz
            scrollableMonthYearDropdown
            onFocus={(e) => (e.target.readOnly = true)}
          /> */}
          <DatePicker
            className="myDatePicker"
            selected={
              inputValue.selectedDate ? new Date(inputValue.selectedDate) : null
            }
            name="selectedDate"
            value={inputValue.selectedDate}
            onChange={(date) => handleInputChange("selectedDate", date)}
            dateFormat="EEE, MMM d"
            minDate={new Date()}
            showYearDropdown
            scrollableMonthYearDropdown
            onFocus={(e) => (e.target.readOnly = true)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2.7}
          sx={{ position: "relative" }}
        >
          <Travelers
            adultsCount={inputValue.adultsCount}
            childrenCount={inputValue.childrenCount}
            infantsCount={inputValue.infantsCount}
            selectedOption={inputValue.selectedOption}
            onAdultsChange={(count) => handleInputChange("adultsCount", count)}
            onChildrenChange={(count) =>
              handleInputChange("childrenCount", count)
            }
            onInfantsChange={(count) =>
              handleInputChange("infantsCount", count)
            }
            onSelectedOptionChange={(option) =>
              handleInputChange("selectedOption", option)
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={1.2}>
          <Button
            fullWidth
            required
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "orange",
              height: "52px",
              textTransform: "none",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default OneWay;
