import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import img from "../../assets/images/fly.png";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DepartureAirportInfo from "./DepartureAirportInfo";
import ArrivalAirportInfo from "./ArrivalAirportInfo";
import LuggageIcon from "@mui/icons-material/Luggage";

const BookingFlights = ({ allData, filteredData, airlineData }) => {
  console.log("airlineData>>>>>>>>>", airlineData?.fares);
  const [showMore, setShowMore] = useState({});

  const toggleShowMore = (index) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [index]: !prevShowMore[index],
    }));
  };
  const showBookingPopup = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      {airlineData && airlineData?.legs?.length > 0 ? (
        airlineData?.legs?.map((item, index) => (
          <Grid
            container
            sx={{
              alignItems: "center",
              marginBottom: "20px",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <Grid
              lg={9}
              md={9}
              sm={9}
              xs={9}
              sx={{
                display: "flex",
                backgroundColor: "#1879c0",
                justifyContent: "space-between",
                alignItems: "center",
                borderTopLeftRadius: "5px",
              }}
            >
              <Typography variant="p">
                {item?.airlineCodes
                  ?.map((code) => {
                    const airline = airlineData?.airlines?.find(
                      (airline) => airline.code === code
                    );
                    return airline ? airline.name : "Unknown";
                  })
                  .map((airlineName, index) => (
                    <span key={index}>&nbsp;{airlineName}&nbsp;&nbsp;</span>
                  ))}
              </Typography>
              <Button
                style={{ color: "white" }}
                onClick={() => toggleShowMore(index)}
              >
                Show Details
                {showMore[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </Grid>
            <Grid container>
              <Grid lg={9} md={9} sm={9} xs={9}>
                <Box key={index}>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      alignItems="center"
                      sx={{ p: "2%", fontSize: "13px" }}
                    >
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <img src={img} alt="img" width="20%" height="20%" />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        sx={{ textAlign: "right", fontSize: "13px" }}
                      >
                        <Typography
                          sx={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.departureTime}
                        </Typography>
                        <DepartureAirportInfo
                          data={airlineData}
                          departureAirportCode={item.departureAirportCode}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        sx={{ textAlign: "center", fontSize: "13px" }}
                      >
                        <Typography variant="p">{item.duration}</Typography>
                        <hr />
                        <Typography variant="p">{item.breake}</Typography>
                        <Typography variant="p" sx={{ fontSize: "10px" }}>
                          {item.stopoverAirportCodes}
                        </Typography>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                          variant="h6"
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.arrivalTime}
                        </Typography>
                        <ArrivalAirportInfo
                          data={airlineData}
                          arrivalAirportCode={item.arrivalAirportCode}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ textAlign: "right" }}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                      >
                        <LuggageIcon
                          sx={{ color: "#336699", cursor: "pointer" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                lg={3}
                md={3}
                sm={3}
                xs={3}
                sx={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  fontSize: "12px",
                }}
              >
                <Typography variant="h6">${item.arrivalTime}</Typography>
                <Typography variant="p">${item.arrivalTime} total</Typography>
                <Button onClick={showBookingPopup} variant="contained">
                  Book Flight
                </Button>
                <Typography variant="p">4 seats left</Typography>
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <InfoIcon sx={{ fontSize: "12px" }} /> Free Cancellation
                </Typography>
              </Grid>
            </Grid>
            {showMore[index] && (
              <Grid container>
                <Grid
                  container
                  spacing={2}
                  sx={{ backgroundColor: "#eff0f6", my: "1%", p: "1%" }}
                >
                  <Grid item lg={9} md={9}>
                    <Typography>OutBound, Mon,Sep 4</Typography>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Typography sx={{ float: "right" }}>
                      {item.duration}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: "1%" }}>
                  <Grid item lg={5} md={5} sm={5} xs={5}>
                    <Typography variant="span">{item.departureTime}</Typography>
                    -<Typography variant="span">{item.arrivalTime}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Typography variant="span">
                      {" "}
                      {item.departureAirportCode}
                    </Typography>
                    -
                    <Typography variant="span">
                      {" "}
                      {item.arrivalAirportCode}
                    </Typography>
                    <br />
                    <img src={img} alt="img" width="30px" />
                    <Typography variant="p">{item.airlineCodes}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography sx={{ float: "right" }}>
                      {item.duration}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <Typography>No flights found</Typography>
      )}
    </Box>
  );
};

export default BookingFlights;
