import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const AdminProfileForm = () => {
  const [userData, setUserData] = useState({
    profilePic: "",
    userName: "",
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
  });

  const [error, setError] = useState({
    profilePic: "",
    userName: "",
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
  });

  const validate = (name, value) => {
    switch (name) {
      case "profilePic":
        if (!value || value.trim() === "") {
          return "profilePic is required";
        } else {
          return "";
        }
      case "userName":
        if (!value || value.trim() === "") {
          return "userName is required";
        } else {
          return "";
        }
      case "email":
        if (!value || value.trim() === "") {
          return "email is required";
        } else {
          return "";
        }

      case "password":
        if (!value || value.trim() === "") {
          return "password is required";
        } else {
          return "";
        }
      case "fullName":
        if (!value || value.trim() === "") {
          return "fullName is required";
        } else {
          return "";
        }
      case "phoneNumber":
        if (!value || value.trim() === "") {
          return "phone number is required";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    setError({ ...error, [name]: validate(name, value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    let validationError = {};
    Object.keys(userData).forEach((name) => {
      const error = validate(name, userData[name]);
      if (error && error.length > 0) {
        validationError[name] = error;
      }
    });
    if (Object.keys(validationError).length > 0) {
      setError({ ...validationError });
    }
  };
  return (
    <Box sx={{ m: "2% 30%", border: "1px solid grey", p: "2%" }}>
      <Grid  container spacing={2}>
        <Grid item lg={12} textAlign="center">
          <Typography variant="h4" >Profile Form</Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="file"
            placeholder="profilePic"
            onChange={handleOnchange}
            value={userData.profilePic.value}
            name="profilePic"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.profilePic}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="text"
            placeholder="UserName"
            onChange={handleOnchange}
            value={userData.userName.value}
            name="userName"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.userName}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="email"
            placeholder="Email"
            onChange={handleOnchange}
            value={userData.email.value}
            name="email"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.email}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="password"
            placeholder="Password"
            onChange={handleOnchange}
            value={userData.password.value}
            name="password"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.password}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="text"
            placeholder="Full Name"
            onChange={handleOnchange}
            value={userData.fullName.value}
            name="fullName"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.fullName}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            required
            type="text"
            placeholder="Phone Number"
            onChange={handleOnchange}
            value={userData.phoneNumber.value}
            name="phoneNumber"
          />
          <Typography variant="span" sx={{ color: "red" }}>
            {error.phoneNumber}
          </Typography>
        </Grid>
        <Grid item lg={12} textAlign="center">
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminProfileForm;
