import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosObj from "../../Services/Api";
import { getCreateFlightSearchRoundTripURL } from "../../Services/apiConfig";

/**
 * created a initial state
 */
const initialState = {
  flightSearchRoundWay: [],
  loading: false,
  error: null,
};

/**
 * createFlightSearchRoundWay function
 */
export const createFlightSearchRoundWay = createAsyncThunk(
  "/createFlightSearchRoundWay",
  async (parameters) => {
    try {
      const response = await axiosObj.post(getCreateFlightSearchRoundTripURL(), parameters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
export const RoundWaySlice = createSlice({
  name: "roundWayDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFlightSearchRoundWay.pending, (state) => {
        state.loading = true;
      })

      .addCase(createFlightSearchRoundWay.fulfilled, (state, action) => {
        state.flightSearchRoundWay = action.payload;
        state.loading = false;
      })

      .addCase(createFlightSearchRoundWay.rejected, (state) => {
        state.flightSearchRoundWay = [];
        state.loading = false;
      });
  },
});

export const getCreateFlightRoundWay = (state) => state.roundWayDetails.createFlightSearchRoundWay;
export const getLoadingState = (state) => state.roundWayDetails.loading;

/**
 * exported the reducer
 */
export default RoundWaySlice.reducer;
